<section id="Careers" class="faq section-pdng">
    <div class="container" data-aos="fade-up">

        <div class="row" [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
            <div class="col-lg-5 d-none d-md-block">
                <img src="../../assets/img/career.jpg" width="360" height="567" alt="career" loading="lazy">
            </div>
            <div class="col-lg-7">
                <h2 class="main-heading-all">
                    Careers
                </h2>
                <p>
                    The vibrant MICE industry in India is full of potential for those looking for a fulfilling and
                    challenging
                    career in this field.
                    As one of the leading entities in the industry, Kesari MICE offers dynamic young individuals the
                    opportunity
                    to be part of
                    a reputed and rapidly growing division of Kesari Tours Pvt. Ltd.</p>
                <p>If you are an enthusiastic and result-oriented salesperson, have prior experience in MICE Sales,
                    and are familiar with destinations in India and abroad, you could be one of our Business Development
                    Executives.
                </p>
                <p class="sub-career-heading">Contact us today!</p>
                <p class="sub-career-heading">Kindly Click on the below links for details regarding the vacancies :</p>
<!-- 
                <ul class="job-list">
                    <li>
                        <i _ngcontent-wnt-c107="" class="bi-check2-all icon-clr"></i> Sales Executive - Mumbai, Pune
                    </li>
                    <li>
                        <i _ngcontent-wnt-c107="" class="bi-check2-all icon-clr"></i> Sales Executive - Mumbai, Pune
                    </li>
                </ul> -->
                <a routerLink="/Careers">
                <button type="button" class="btn btn-primary btn-sm fw-bold read-morebtn">
                    View More </button>
                 </a>
                    

                <!-- <div class="faq-list">
            <ul>
                <li data-aos="fade-up" data-aos-delay="200">
                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed"
                        id="job-sub-headig">Sales Executive - Mumbai, Pune
                        <mat-icon>{{
                            isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"
                            }}</mat-icon></a>
                    <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                        <div class="col-xs-12 box-title-with-border-bottom">
                            <div class="row">
                                <div class="col-sm-2 col-xs-6">
                                    <p>Experience</p>
                                    <strong class="job-loca-txt">4-7 years</strong>
                                </div>
                                <div class="col-sm-2 col-xs-6">
                                    <p>Date posted</p>
                                    <strong class="job-loca-txt"></strong>
                                </div>
                                <div class="col-sm-2 col-xs-6">
                                    <p>Primary location</p>
                                    <strong class="job-loca-txt">Mumbai, Pune</strong>
                                </div>
                                <div class="col-sm-2 col-xs-6">
                                    <p>No. of postion</p>
                                    <strong class="job-loca-txt">1</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 p-style">
                            <h3>Job Description</h3>
                            <ul type="bullet">
                                <li class="description-text">Should develop business and contribute in increasing
                                    revenue and market share of the Company.</li>
                                <li class="description-text">Should operate Sales & Operations for MICE movements and
                                    Corporate clients.</li>
                                <li class="description-text">Should keep track of competition, make commercial charts
                                    and maintain client data base.</li>
                                <li class="description-text">Should have knowledge of various destinations / products.
                                </li>
                                <li class="description-text">Should ensure good customer service to all guests.
                                </li>
                            </ul>

                        </div>
                        <button type="button" class="btn btn-primary">Apply Now</button>
                    </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed"
                        id="job-sub-headig">Sales Executive - Mumbai, Pune
                        <mat-icon>{{
                            isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"
                            }}</mat-icon></a>
                    <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                        <div class="col-md-12 col-xs-12 box-title-with-border-bottom">
                            <div class="row">
                                <div class="col-md-4 col-sm-2 col-xs-6">
                                    <p>Experience</p>
                                    <strong class="job-loca-txt">4-7 years</strong>
                                </div>
                                <div class="col-md-4 col-sm-2 col-xs-6">
                                    <p>Date posted</p>
                                    <strong class="job-loca-txt"></strong>
                                </div>
                                <div class="col-md-4 col-sm-2 col-xs-6">
                                    <p>Primary location</p>
                                    <strong class="job-loca-txt">Mumbai, Pune</strong>
                                </div>
                                <div class="col-md-4 col-sm-2 col-xs-6">
                                    <p>No. of postion</p>
                                    <strong class="job-loca-txt">1</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 p-style">
                            <h3>Job Description</h3>
                            <ul type="bullet">
                                <li class="description-text">Giving quotations, speaking with suppliers,negotiating
                                    rates.</li>
                                <li class="description-text">Establishing new suppliers.</li>
                                <li class="description-text">Understand competitors strategies.</li>
                                <li class="description-text">Working towards zero issues/concerns on tours.</li>
                                <li class="description-text">Creating Domestic & International Database.</li>
                                <li class="description-text">To understand the guest target of the organization.</li>
                            </ul>

                        </div>
                        <button type="button" class="btn btn-primary">Apply Now</button>
                    </div>
                </li>

            </ul>
        </div> -->
            </div>

        </div>




    </div>
</section>