
import { ShareService } from '../service/share.service';
import { kesariAnimations } from '../animation';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
  animations: kesariAnimations,
})
export class TabViewComponent implements OnInit {
  itinenary: any;
  width: number = window.innerWidth;
  mobileWidth: number = 768;
  constructor(private sharedservice: ShareService ,private router: Router 
  ) { }

  ngOnInit(): void {
    this.getCountries()

  }
  getCountries() {
    this.sharedservice.getCountries().subscribe((data) => {
     // console.log('countries', data);
      this.itinenary = data.filter((item) => item.isActive === true);
     // console.log('active countries', this.itinenary);
    });
  }
  
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    stagePadding: 10,
    dots:false,
    autoWidth: true,
   
    
    navText: [ '<img src="../../assets/img/left1.png" width="18" height="31" loading="lazy" alt="corporate tours">', '<img src="../../assets/img/right1.png" width="18" height="31" loading="lazy" alt="corporate tours">' ],
    //items:8, 
    responsive:{
      0:{
          items:1.3,
          nav:false,
          center:true,
          margin:10
      },
      600:{
          items:1.3,
          nav:false,
       
          center:true,
          margin:10
          
      },
      1000:{
          items:4,
          nav:true,
          loop:false
      }
  },
  nav:true
  };  

  getWindowWidth(): number {
    return window.innerWidth;
  }

  navigateToItineraries(country: string) {
    const decodedCountry = decodeURIComponent(country);
    const modifiedCountry = decodedCountry.replace(/%20/g, ' ');
    const lowercaseCountry = modifiedCountry.toLowerCase().replace(/\s+/g, '-');
    this.router.navigate(['/Itineraries', lowercaseCountry]);
    // console.log('Modified country:', lowercaseCountry);
  }
  
  
  
  
  
  
}
