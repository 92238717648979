import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-view',
  templateUrl: './listing-view.component.html',
  styleUrls: ['./listing-view.component.scss']
})
export class ListingViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
