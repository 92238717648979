import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ShareService } from "../service/share.service";
import { kesariAnimations } from "../animation";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
// declare var Timeline: any;
// declare function select(): any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: kesariAnimations
})
export class HomeComponent implements OnInit {
  itinary: []
  metaData = {
    isProduct: false,
    page: "MICE-HOME"
  }
  url: string;
  constructor(@Inject(DOCUMENT) private dom, private miceService: ShareService, private renderer: Renderer2, private elRef: ElementRef, private meta: Meta, private title: Title,  private router: Router,
    private activatedRoute: ActivatedRoute ) {



  }
  // setTitle(newTitle: string) {
  //   this.title.setTitle(newTitle);
  // }

  ngOnInit(): void {
    this.updateSeo()
   
    // this.title.setTitle("World Class Travel Company:Group Tours|Speciality Tours");

    // this.meta.addTags([
    //   {name:'description', content:'Home'},
    //   {name:'keywords', content:'kesari mice'}
    // ])
    // this.setTitle('Home Page')
    // this.miceService.getArticles().subscribe((param) => {
    //   console.log(param)
    // })
    // this.miceService.getMiceGallery().subscribe((param) => {
    //   console.log(param)
    // })
    // this.miceService.getMiceWebBanner().subscribe((param) => {
    //   console.log(param)
    // })
    // this.miceService.getPartner().subscribe((param) => {
    //   this.itinary=param,
    //   console.log(this.itinary,"itinary")
    // })

  }
  updateSeo() {
    
    this.miceService.getMetaData(this.metaData).subscribe((data) => {
      // console.log(data)
      if (data.length != 0) {
        this.meta.updateTag({ property: 'og:title', content: data[0].meta_title });
        this.meta.updateTag({ name: 'keywords', content: data[0].meta_keyword });
        this.meta.updateTag({ name: 'description', content: data[0].meta_description });
        this.title.setTitle(data[0].meta_title);
        // console.log(data[0].meta_description)
      }

    })
    // this.miceService .setCanonicalURL();
    // this.updateCanonicalUrl()
    
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.location.origin);
    // console.log('href',this.dom.location.origin)
    // this.router(
      
    //   filter(event => event instanceof NavigationEnd),
    // ) .subscribe((data) => {
    //   if (data.canonical) {}
      
    // })
  }

  updateCanonicalUrl(){
    // let link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', this.dom.URL);
    // console.log(this.dom.URL)
  }
  // scrollToContact() {
  //   const contactElement = this.elRef.nativeElement.querySelector('#Contact');
  //   const yOffset = -100;
  //   const y = contactElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //   window.scrollTo({ top: y, behavior: 'smooth' });
  // }

}
