import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
import { ShareService } from '../service/share.service';

@Component({
  selector: 'app-gallaryhome',
  templateUrl: './gallaryhome.component.html',
  styleUrls: ['./gallaryhome.component.scss']
})
export class GallaryhomeComponent implements OnInit {
  galleryItem: any;
  currentIndex: any = -1;

  constructor(private miceService: ShareService,) { }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    stagePadding: 10,
    dots: false,
    autoWidth: true,


    navText: ['<img src="../../assets/img/left1.png" alt="corporate tours">', '<img src="../../assets/img/right1.png" alt="corporate tours">'],
    //items:8, 
    responsive: {
      0: {
        items: 1.3,
        nav: false,
        center: true,
        margin: 10
      },
      600: {
        items: 1.3,
        nav: false,

        center: true,
        margin: 10

      },
      1000: {
        items: 4,
        nav: true,
        loop: false
      }
    },
    nav: true
  };
  ngOnInit(): void {
    this.getGallery()
  }



  getGallery() {
    this.miceService.getMiceGallery().subscribe((param) => {
  
      this.galleryItem = param.data
      console.log(this.galleryItem)
    })
  }
}
