import { Component, OnInit } from "@angular/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ShareService } from "../service/share.service";
import { kesariAnimations } from "../animation";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  animations: kesariAnimations,
})
export class CarouselComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: false,
    animateOut: 'fadeOut',
    dots: false,
    navSpeed: 1000,
    navText: [ '<img src="../../assets/img/left.png" width="22" height="38" loading="lazy" alt="business and corporate tour">', '<img src="../../assets/img/right.png" width="22" height="38" loading="lazy" alt="business and corporate tour">' ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 768;
  property: any;
  isMobile: boolean = false;
  constructor(private miceService: ShareService) { }

  ngOnInit(): void {
    this.getBanner()
   }
  getBanner() {
    this.miceService.getMiceWebBanner()
      .subscribe(param => {
        this.property = param.data
      //  console.log('banner web',this.property)
      });
      this.isMobile = this.width < this.mobileWidth;

  }
}
