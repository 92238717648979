<!-- ======= call-to-action Section ======= -->
<section id="cta" class="cta">
  <div class="cta__overlay">
    <div class="container">
      <div class="row" data-aos="zoom-in">
        <div class="col-lg-7 col-sm-7 text-center text-lg-start">
          <h2 class="main-heading-all text-white">Need Assistance</h2>
          <p>
            Need Help? Call us or drop a message.<br>
            Our customer care will be in touch shortly.
          </p>
        </div>
        <div class="col-lg-5 col-sm-5 cta-btn-container text-center">
          <a class="cta__text align-middle" href="tel:18002661100">
            <img src="../../assets/img/phone.png" width="35" height="46" loading="lazy" alt="kesari mice contact number 1800 22 1100" title="kesari mice contact number 1800 22 1100">18002661100</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End call-to-action Section -->