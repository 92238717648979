<div class="row mice-enquiry">
  <div class="col-md-4 pd-0 d-none d-md-block" [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
    <img class="mice-enquiry__img" src="../../../assets/img/enquiry.jpg" width="300" height="536" alt="enquiry" loading="lazy">
  </div>
  <div class="col-md-8 mice-enquiry__wrapper">
    <h2 class="mice-enquiry__title">Corporate Tour Enquiry</h2>
    
    <button aria-label="Close" type="button" mat-dialog-close class="btn btn-light map-close btn-close"></button>
   
    <div class="scroll" *ngIf="formview" [@animate]="{value:'*',params:{delay:'15ms',x:'-25px'}}">
      <form class="mice-enquiry__form needs-validation" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="row ">
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" name="Name" formControlName="Name" placeholder="Your Name">
              <label for="Name">Your Name*</label>
              <div *ngIf="contactForm.controls['Name'].invalid && contactForm.controls['Name'].touched">
                <span *ngIf="checkError('Name', 'required')">*Please enter your name.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="email" class="form-control" formControlName="email" placeholder="Email address">
              <label for="email">Email address*</label>
              <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched">
                <span *ngIf="checkError('email', 'required')">*Please enter email address.</span>
                <span *ngIf="checkError('email', 'email')"></span>
                <span *ngIf="checkError('email', 'pattern')">*Please enter a valid email address.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="contact" placeholder="Phone Number"
                maxlength="10">
              <label for="contact">Phone Number*</label>
              <div *ngIf="contactForm.controls['contact'].invalid && contactForm.controls['contact'].touched">
                <span *ngIf="checkError('contact', 'required')">*Please enter a phone number..</span>
                <span *ngIf="checkError('contact', 'maxlength')"></span>
                <span *ngIf="checkError('contact', 'pattern')">Please enter valid number.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="companyname" placeholder="Phone Number">
              <label for="companyname">Company Name*</label>
              <div *ngIf="contactForm.controls['companyname'].invalid && contactForm.controls['companyname'].touched">
                <span *ngIf="checkError('companyname', 'required')">*Please enter company name.
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="NoOfDays" placeholder="No. of Days "
                maxlength="2">
              <label for="NoOfDays">Enter number of days</label>
              <div *ngIf="contactForm.controls['NoOfDays'].invalid && contactForm.controls['NoOfDays'].touched">
                <span *ngIf="checkError('NoOfDays', 'pattern')">Please include No. of Days
                  field.</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" formControlName="destination" placeholder="Phone Number">
              <label for="destination">Destination</label>
              <!-- <div *ngIf="contactForm.controls['destination'].invalid && contactForm.controls['destination'].touched">
              <span *ngIf="checkError('destination', 'required')">Please include your Destination Name in the required
                field.</span>
            </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <select class="form-control select" formControlName="grptype" aria-label="grptype">
                <option value="" selected disabled hidden>What are you looking at</option>
                <option value="Meetings">Meetings</option>
                <option value="Incentive tours">Incentive tours</option>
                <option value="Conference">Conference</option>
                <option value="Exhibition">Exhibition</option>
                <option value="Educational tours">Educational tours</option>                
              </select>
              
              <!-- <div *ngIf="contactForm.controls['grptype'].invalid && contactForm.controls['grptype'].touched">
              <span *ngIf="checkError('grptype', 'required')">Please include your Destination Name in the required
                field.</span>
            </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="date" class="form-control" formControlName="dateOfTravel" placeholder="Phone Number">

              <div *ngIf="contactForm.controls['dateOfTravel'].invalid && contactForm.controls['dateOfTravel'].touched">

                <!-- <span *ngIf="checkError('dateOfTravel','required')">Please include the date of travel in the required field.</span> -->
                <span *ngIf="checkError('dateOfTravel','invalidDate')">Ensure that the date of travel field is filled
                  with the appropriate date.</span>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-floating">
              <textarea class="form-control" placeholder="Tour Details"
                formControlName="description"></textarea>
              <label for="description">Tour Details</label>
              <!-- <div *ngIf="contactForm.controls['description'].invalid && contactForm.controls['description'].touched">
              <span *ngIf="checkError('description', 'required')">Please include your Description in the required field.</span>
            </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-start fix-btn">
            <button class="btn btn-primary btn-lg btn-rounded" type="submit"
              [disabled]="!contactForm.valid || disableSubmitbtn">Submit</button>
          </div>
        </div>
      </form>  
      <div class="thank-you" *ngIf="thankyouview" [@animate]="{value:'*',params:{delay:'10ms',x:'-25px'}}">
        <div>
          <img src="../../../assets/img/thank-you.png" alt="thank you" loading="lazy">
          <h3 class="thank-you__title">Thank you for submitting your enquiry!</h3>
          <p class="thank-you__msg">We have received your enquiry and our team is actively reviewing it.</p>
          <p> We value your time and will respond to your query as quickly as possible. </p>
          <button type="submit" class="btn btn-primary btn-lg btn-rounded " mat-dialog-close><i
              class="fa fa-arrow-alt-circle-left"></i>Back to Site</button>
        </div>
      </div>    
    </div>

    
  </div>
</div>