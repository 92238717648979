import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dif-tepmt-card',
  templateUrl: './dif-tepmt-card.component.html',
  styleUrls: ['./dif-tepmt-card.component.scss']
})
export class DifTepmtCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
