import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ShareService } from "../service/share.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  formview: boolean;
  thankyouview: boolean;
  showSuccessMessage: boolean = false;
  showWarningMessage: boolean = false;
  successMesage: string;
  errorMessage: any;
  disableSubmitbtn: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private miceService: ShareService,
    private toastrService: ToastrService,
    private el: ElementRef, private renderer: Renderer2
  ) {}

  // public showSuccess(): void {
  //   this.toastrService.success("Message Success!", "Title Success!");
  // }
  // public showWarning(): void {
  //   this.toastrService.warning("Message Warning!", "Title Warning!");
  // }
  ngOnInit(): void {
    this.getForm();
  }

  getForm() {
    this.contactForm = this.formBuilder.group({
      Name: [
        "",
        [Validators.required /*Validators.pattern(/^[a-zA-Z ' " .-]+$/)]*/],
      ] /* (/^[A-Za-z -]+$/) */ /* , Validators.pattern(/^[a-zA-Z .-]+$/) */,
      companyname: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
        ],
      ],

      contact: [
        "",
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/),
        ],
      ],
      grptype: [""],
      NoOfDays: ["", [Validators.pattern(/^[1-9]{1,4}$/)]],
      destination: [""],
      dateOfTravel: ["", [this.dateValidator]],
      description: ["", [Validators.maxLength(200)]],
    });
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.contactForm.controls[controlName].hasError(errorName);
  };
  dateValidator(control: FormControl): { [key: string]: any } | null {
    const inputDate = new Date(control.value);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    if (inputDate <= currentDate) {
      return { invalidDate: true };
    }

    return null;
  }
  scrollToContact() {
    debugger
    const contactElement = this.el.nativeElement.querySelector('#Contact');
    const yOffset = -100;
    const y = contactElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
  onSubmit() {
    if (this.contactForm.valid) {
      console.log(JSON.stringify(this.contactForm.value, null, 4));

      this.miceService.getEnquiryForm(this.contactForm.value).subscribe(
        (response) => {
          if (response.message == "Successfully Saved Your Lead") {
           

            this.toastrService.success(
              "Your Response has been submitted succesfully !",
              "Thank you",
              {
               // positionClass: "toast-top-center",
              }
            );
            this.contactForm.reset();
            // this.showSuccessMessage = true;
          } else {
            // this.loading = false;
            var message = "Form Submission Failed";
            if (response.message) {
              // message = response.message;
              this.toastrService.error(
                "Form Submission Failed"
              );
            }
          }
        },
        (error) => {
          // this.loading = false;
          //var message = "Sorry Failed to Submit Form. Please Try Again.";
          this.toastrService.error(
            "Sorry Failed to Submit Form. Please Try Again."
          );
          // setTimeout(() => {
          //   this.errorMessage = " ";
          // }, 30000);
        }
      );
    } else {
      this.toastrService.error("Form has some Issues")
      // this.errorMessage = "Form has some Issues"; //Error Mesage
      // this.loading = false;
    }
  }
}
