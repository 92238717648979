import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { NgbCollapseModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";

import { HorizontalViewComponent } from "./horizontal-view/horizontal-view.component";
import { ListingViewComponent } from "./listing-view/listing-view.component";
import { SliderViewComponent } from "./slider-view/slider-view.component";
import { AccordionViewComponent } from "./accordion-view/accordion-view.component";
import { VerticalViewComponent } from "./vertical-view/vertical-view.component";
import { TabViewComponent } from "./tab-view/tab-view.component";
import { VerticalViewPhotosComponent } from "./vertical-view-photos/vertical-view-photos.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { MaterialModuleModule } from "./material-module/material-module.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-owl-carousel-o";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CallToActionComponent } from "./call-to-action/call-to-action.component";
import { PricingListComponent } from "./pricing-list/pricing-list.component";
import { FullAccordionViewComponent } from "./full-accordion-view/full-accordion-view.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { DifTepmtCardComponent } from "./dif-tepmt-card/dif-tepmt-card.component";
import { TestimonialSectionComponent } from './testimonial-section/testimonial-section.component';
import { TestimonialComponent } from './testimonial-section/testimonial/testimonial.component';
import { RouterModule } from "@angular/router";

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
// import { NewArticalSectionComponent } from './new-artical-section/new-artical-section.component';
// import { MiceCountryStateComponent } from './mice-country-state/mice-country-state.component';
import { CareerSectionComponent } from './career-section/career-section.component';
import { NewaccordionComponent } from './newaccordion/newaccordion.component';
import { EnquiryComponent } from './layout/enquiry/enquiry.component';
// import { CareerformComponent } from './career-section/careerform/careerform.component';
// import { CareerpageComponent } from './career-section/careerpage/careerpage.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
// import { LightboxModule } from "ngx-lightbox";
// import { LightboxModule } from "ngx-lightbox";
// import { LightboxModule } from "ngx-lightbox/lightbox.module";
// import { LightboxModule } from '@ngx-gallery/lightbox'

import { ToastrModule } from 'ngx-toastr';
import { TermsComponent } from './terms/terms.component';
import { GallaryhomeComponent } from "./gallaryhome/gallaryhome.component";
// import { ServerModule } from '@angular/platform-server';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HorizontalViewComponent,
    ListingViewComponent,
    SliderViewComponent,
    AccordionViewComponent,
    VerticalViewComponent,
    TabViewComponent,
    VerticalViewPhotosComponent,
    CarouselComponent,
    CallToActionComponent,
    PricingListComponent,
    FullAccordionViewComponent,

    ContactUsComponent,
    DifTepmtCardComponent,

    //TestimonialSectionComponent,
    // TestimonialComponent,

    // NewArticalSectionComponent,
    // MiceCountryStateComponent,
    CareerSectionComponent,
    NewaccordionComponent,
    EnquiryComponent,
    TermsComponent,
    GallaryhomeComponent,
    // CareerformComponent,
    // CareerpageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbCollapseModule,
    NgbCarouselModule,
    MaterialModuleModule,
    BrowserAnimationsModule,
    CarouselModule,
    
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    RouterModule,
    NgxJsonLdModule,
    LightboxModule,
    GalleryModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      timeOut: 15000,

      positionClass: 'toast-top-center',
    },

    ),
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
