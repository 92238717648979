import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { MemorableSectionComponent } from "./memorable-section/memorable-section.component";
import { TestimonialSectionComponent } from "./testimonial-section/testimonial-section.component";

// import { NewArticalSectionComponent } from "./new-artical-section/new-artical-section.component";
// import{ MiceCountryStateComponent } from "./mice-country-state/mice-country-state.component";
// import { CareerSectionComponent } from "./career-section/career-section.component";
// import { NewaccordionComponent } from "./newaccordion/newaccordion.component";
import { CareerpageComponent } from "./career-section/careerpage/careerpage.component";
import { TestimonialModule } from "../app/testimonial-section/testimonial.module";
import { TermsComponent } from "./terms/terms.component";


const routes: Routes = [
  { path: "", component: HomeComponent },

  { path: "terms-conditions", component: TermsComponent },

  {
    path: '', 
    loadChildren: () => import('../app/memorable-section/memorable.module').then(m => m.MemorableModule)
  },
  // { path: "Memorable", component: MemorableSectionComponent },
  // { path: "Testimonial", component: TestimonialSectionComponent },

  //  Gallery
  {
    path: '',
    loadChildren: () => import('../app/photo-gallery-section/gallery.module').then(m => m.GalleryModule)
  },
{
  path: '', 
  loadChildren: () => import('../app/new-artical-section/article.module').then(m => m.ArticleModule)
},
{
  path:'',
  loadChildren:()=>import('../app/testimonial-section/testimonial.module').then(m=> m.TestimonialModule)
},
  // { path: "Article-Corner", component: NewArticalSectionComponent },

  // { path: "Careers", component: CareerpageComponent },
  // Careers
  {
    path: '', 
    loadChildren: () => import('../app/career-section/career.module').then(m => m.CareerModule)
  },
  {
    path: 'Itineraries',
    loadChildren: () => import('../app/mice-country-state/country.module').then(m => m.CountryModule)
  },
  // { path: "viewstate" , component: MiceCountryStateComponent },
  // { path: "Careers" , component: CareerSectionComponent },
  // { path: "accordion-sample", component: NewaccordionComponent},
  { path: "**", component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    enableTracing: false,
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
