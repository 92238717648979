<!-- =======vertical-view Services Section ======= -->
<section id="Corporate-Travel-Services" class="services padd-sec">
  <div class="container" data-aos="fade-up">
    <h2 class="main-heading-all text-center">Corporate Travel Services</h2>
    <p class="mb-30 mob-center">
      Corporate travel services offered by Kesari MICE include several travel
      and tour packages which include several recreational and motivational
      activities. Corporate travel services offered by Kesari are amongst the
      most innovative travel packages offered to diverse corporate clients.
    </p>
    <div class="row justify-content-center  desk-vw">
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch " data-aos="fade-up" data-aos-delay="100">
        <div class="icon-box">                    
          <h4>Corporate Incentive Tours</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="200">
        <div class="icon-box">                    
          <h4>
            Company off-sites
          </h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="300">
        <div class="icon-box">          
          <h4>
            Conference Management Services
          </h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="400">
        <div class="icon-box">          
          <h4>
            Customized Group Holiday
          </h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="500">
        <div class="icon-box">
          <h4>Destination Wedding</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="500">
        <div class="icon-box">          
          <h4>Event Planning and Themes</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="500">
        <div class="icon-box">          
          <h4>Foreign Exchange Services</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div class="icon-box">          
          <h4>Venue Sourcing & Destination Management</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div class="icon-box">          
          <h4>Industrial visits for Students</h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div class="icon-box">          
          <h4>Association groups </h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div class="icon-box">          
          <h4>
            Agricultural Study Tours for Farmers
          </h4>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-4 d-flex align-items-stretch  mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div class="icon-box">          
          <h4>Trade fairs</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!--vertical-view End Services Section -->