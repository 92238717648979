<section id="Gallery" class="portfoio section-pdng ">
    <div class="container pds-0" data-aos="fade-up">

        <h2 class="main-heading-all text-center">Gallery</h2>

        <div class="portfolio-section position-relative">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let item of galleryItem">
                    <ng-template carouselSlide>
                        <img [src]="item.image"
                            class="portfolio-section__itinaries" alt="kesari mice" loading="lazy">
                    </ng-template>
                    <!-- <ng-template carouselSlide>
                        <img src="https://image.kesari.in/upload/homepageImage/Dubai_2018.jpg"
                            class="portfolio-section__itinaries" alt="kesari mice" loading="lazy">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="https://image.kesari.in/upload/homepageImage/Dubai_2018.jpg"
                            class="portfolio-section__itinaries" alt="kesari mice" loading="lazy">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="https://image.kesari.in/upload/homepageImage/Dubai_2018.jpg"
                            class="portfolio-section__itinaries" alt="kesari mice" loading="lazy">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="https://image.kesari.in/upload/homepageImage/Dubai_2018.jpg"
                            class="portfolio-section__itinaries" alt="kesari mice" loading="lazy">
                    </ng-template> -->
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="text-center mt-3">
            <a routerLink="/Gallery">
                <button type="button" class="btn btn-primary btn-sm fw-bold  read-morebtn">
                    View All
                </button>
            </a>
        </div>

    </div>
</section>