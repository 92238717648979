<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div  class="col-lg-3 col-md-6 col-12 footer-links">
           <a routerLink="/"> 
          <img src="../../../assets/img/footer-logo.jpg" width="120" height="48" alt="footer-logo" loading="lazy">
          </a>
            <ul style="list-style: none;padding-left: 0;margin-top: 20px;">
              <li>Land Square Shopping Complex, 2nd Floor, Fergusson College Rd, <br>Next to Hotel Rupali, <br>Ganeshwadi, Shivajinagar, <br>Pune, Maharashtra 411004 
              </li>
              <li>
                <i class="bi bi-envelope-fill"></i><a href="mailto:sales@kesarimice.in">sales@kesarimice.in</a>
              </li>
              <li><a href="tel: 020 6646 5555"><i class="fa fa-phone-alt rotate"></i> 020 6646 5555</a></li>
              <li> <a href="tel: 18002661100"><i class="fa fa-phone-alt rotate"></i> 18002661100 </a> &nbsp;(Toll-free)</li>
             
              
              </ul>
              <ul class="social-links clearfix">
                  <!-- <li><a target="_blank" href="https://www.facebook.com/KesariTours" class="facebook"><i class="fa-brands fa-facebook-f"></i></a></li>
                  <li><a target="_blank" href="https://in.linkedin.com/company/kesari-tours" class="instagram"><i class="fa-brands fa-instagram"></i></a></li> -->
                  <li><a target="_blank" href="https://x.com/KesariMICE" class="twitter"><i class="fa-brands fa-x-twitter"></i></a></li>
                  <!-- <li><a target="_blank" href="#" class="youtube"><i class="fa-brands fa-youtube"></i></a></li> -->
                  <li><a target="_blank" href="https://kesarimice.blogspot.in/" class="linkdin"><i class="fa-solid fa-blog"></i></a></li>
                  <li><a target="_blank" href="https://in.linkedin.com/in/kesari-mice-4241712a" class="linkdin"><i class="fa-brands fa-linkedin-in"></i></a></li>
              </ul>
          </div>
        <div class="col-lg-3 col-md-6 col-6 footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a class="nav-link scrollto" href="#Home">Home</a></li>
            <li>
              <a class="nav-link scrollto" href="#About-Us">About Us</a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#Corporate-Travel-Services">Corporate Travel Services  </a>
            </li>
            <li>
              
              <a class="nav-link scrollto" href="#Itineraries">Itineraries</a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#Mice-Tourism">MICE Tourism </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 col-6 footer-links">
          <h4>MICE Itineraries</h4>
          <ul>
            <li><a routerLink="/Itineraries/india">India</a></li>
            <li>
              
              <a routerLink="/Itineraries/united-arab-emirates">Dubai</a>
            </li>
            <li>
              
              <a routerLink="/Itineraries/malaysia">Malaysia</a>
            </li>
            <li>
              <a routerLink="/Itineraries/singapore">Singapore</a>
            </li>
            <li>
              <a routerLink="/Itineraries/hong-kong">Hong Kong</a>
            </li>
            <li>
              
              <a routerLink="/Itineraries/thailand">Thailand</a>
            </li>
            <li>
              
              <a routerLink="/Itineraries/sri-lanka">Sri Lanka</a>
            </li>
            <li>
              <a routerLink="/Itineraries/switzerland">Switzerland</a>
            </li>
          </ul>
        </div>
     

        <div class="col-lg-3 col-md-6 col-6 footer-links">
          <h4>Guest Help links</h4>
          <ul>
            <li>
              <a routerLink="/Gallery">Photo Gallery</a>
            </li>
            <li>
              
              <a href="#Careers">Careers</a>
            </li>
            <li>
              
              <a href="#Contact">Contact Us</a>
            </li>
            <li>
              
              <a href="#Happy-Corporate">Happy Corporate</a>
            </li>
            <li>
              
              <a routerLink="/Article-Corner">News & Articles</a>
            </li>
            <li>
              
              <a routerLink="/Testimonial">Testimonials</a>
            </li>
            <li>
              
              <a routerLink="/terms-conditions">Terms And Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button class="btn-enquiry " type="button" (click)="openDialog()"><i class="fa fa-edit"></i> Enquiry Now</button>
    <button *ngIf="windowScrolled" class="to-top" (click)="gotoTop()">
      <!-- 👆 --> <i class="bi bi-arrow-up"></i>
    </button>

  
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright
      <span>KesariMICE {{currentYear}}
        <!-- {{lastYear}}2012-2013 --> </span>. All Right Reserved.
    </div>
  </div>
</footer>
<!-- End Footer -->