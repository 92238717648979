import { Component, ElementRef, Inject, OnInit , PLATFORM_ID, Renderer2} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryComponent } from '../enquiry/enquiry.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  windowScrolled = false;

  year: number;
  lastYear: number;
  currentYear: number=new Date().getFullYear();
  topPosToStartShowing = 100;
  constructor(public dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: any, private el: ElementRef, private renderer: Renderer2) {
    this.year = new Date().getFullYear();
    this.lastYear = this.year - 1;
   
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)){
    setTimeout(() => {
      this.openDialog(); // Call the openDialog function after the delay
    }, 10000); 
  
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  
    this.scrollToTop();
  }
}
  
  // openDialog() {    
  //   this.dialog.open(EnquiryComponent, {
  //     disableClose: true,
  //     hasBackdrop: true,
  //     width: '900px',
  //   })



  // }


  openDialog(): void {
    const dialogRef = this.dialog.open(EnquiryComponent, {
      width: '900px',
    });
  
    dialogRef.afterOpened().subscribe(() => {
      // Find the dialog container element by class name or any other means if necessary
      const dialogContainer = document.querySelector('.mat-dialog-container');
  
      // Check if the element exists
      if (dialogContainer) {
        // Set the role attribute to 'dialog'
        this.renderer.setAttribute(dialogContainer, 'role', 'dialog');
      }
    });
  
    dialogRef.afterClosed().subscribe(() => {
      console.log('Dialog closed');
    });
  }


  scrollToTop(): void {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false
    }

  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
