<!-- ======= Frequently Asked Questions Section ======= -->
<section id="Happy-Corporate" class="faq section-bg section-pdng ">
  <div class="container" data-aos="fade-up">
    <h2 class="main-heading-all text-center">Happy Corporates</h2>
    <mat-accordion>

      <mat-expansion-panel class="panel-sepration">
        <mat-expansion-panel-header class="main-accordion-hedgs">Parliamentary Delegation</mat-expansion-panel-header>
        <mat-accordion class="example-headers-align">
          <p>
            A high level Parliamentary Delegation from Maharashtra decided to
            visit the UK Parliament, CPA, HQ London and other European
            countries. We accepted this logistically challenging opportunity,
            drew up an itinerary that suited the status of this high profile
            group and executed it efficiently. A warm welcome specially
            arranged at Mt. Titlis left the group thrilled. At Zurich as well
            as Rome, The Mayor and the first citizen felicitated this
            delegation. Services of the Kesari MICE tour manager was also
            appreciated.
          </p>
        </mat-accordion>
      </mat-expansion-panel>
      <mat-expansion-panel class="panel-sepration">
        <mat-expansion-panel-header class="main-accordion-hedgs">Insurance Company / Giant</mat-expansion-panel-header>
        <mat-accordion class="example-headers-align">
          A big corporate from the Insurance industry wanted to organize a
          Convention in Malaysia for its agents pan India. Kesari MICE
          handled the arrangements flawlessly right from picking up the
          Agents from their hometowns in remote parts of India and ensuring
          that they board the plane at the respective city airports to the
          Genting Highlands tour for all 650 members with a show at Genting.
          The icing on the cake was the high tea at Kuala Lumpur’s KL Tower!
        </mat-accordion>
      </mat-expansion-panel>

      <mat-expansion-panel class="panel-sepration">
        <mat-expansion-panel-header class="main-accordion-hedgs">Special Needs Group</mat-expansion-panel-header>
        <mat-accordion class="example-headers-align">
          It is a very rare sight. A group of physically challenged adults
          admiring the beauty of the glittering temples at Bangkok, enjoying
          the Coral Island speed boat ride at Pattaya and having
          unimaginable fun at the Nong Nooch village. The group was all
          praise for Kesari MICE for making their dreams come true.
        </mat-accordion>
      </mat-expansion-panel>

      <mat-expansion-panel class="panel-sepration">
        <mat-expansion-panel-header class="main-accordion-hedgs">Marathi International Film and Theater
          Awards</mat-expansion-panel-header>
        <mat-accordion class="example-headers-align">
          The venue was studded with glittering stars and Kesari MICE was
          with them which made us swell with pride. Here, we are talking
          about the film stars who traveled with Kesari MICE on Mhaiskar -
          MIFTA (Marathi International Film and Theater awards) Tour to
          Singapore.The vibrant city of Singapore was very hospitable to the
          group. The fraternity included the 'Who's Who" from the Marathi
          Film and Theater industry who were all set to celebrate the
          prestigious Mhaiskar MIFTA Awards 3rd edition. Kesari MICE ensured
          them a world class experience which included stay in a five star
          hotel and the award ceremony at Kalang Theatre. The trip was
          excellent despite the challenge of getting the visas done for all
          celebrities and making arrangements for all their onward tours.
          The organizers were all praise for Kesari MICE.
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>


  </div>
</section>



<!-- ======= Why Us Section ======= -->