import { Component, OnInit } from '@angular/core';
import { kesariAnimations } from '../animation';
import { ShareService } from '../service/share.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  animations: kesariAnimations,
})
export class TermsComponent implements OnInit {

  constructor(private miceService: ShareService,private meta: Meta, private title: Title) { }

  ngOnInit(): void {
    this.meta.updateTag({ property: 'og:title', content: 'Terms and Conditions | Kesari Mice' });
    this.meta.updateTag({ name: 'keywords', content: 'terms and conditions' });
    this.meta.updateTag({ name: 'description', content: 'Know more about Kesari Mice, terms and conditions while travelling with us!' });
    this.title.setTitle('Terms and Conditions | Kesari Mice');
    this.miceService.setCanonicalURL();
  }

}
