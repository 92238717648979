import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ShareService {
  constructor(public http: HttpClient, @Inject(DOCUMENT) private dom) { }
  getItinerary = () => {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/corporate_itineraries`)
      .pipe(
        map((user: any) => {
          // if (user.code === 2000) {
          //   // console.log('profile details', user.data)
          //   return new user(user.data);
          // }
          return user;
        })
      )
  }
  getCareerInfo() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/miceCareer`)
      .pipe(
        map((user: any) => {
          if (user.status === true) {
            // console.log('profile details', user.data)
            return user.data;
          }
          // return user;
        })
      )
  }
  getCountries() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/corporate_countries`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getEnquiryForm(params: any) {
    return this.http.post(`${environment.apiLoginUrl}/route/miceForm/miceWebLead`, params).pipe(
      map((user: any) => {
        // console.log('feedback', user)
        return user;
      })
    )
  }
  getCareer(params: any) {
    return this.http.post(`${environment.apiLoginUrl}/route/miceForm/miceCareer`, params).pipe(
      map((user: any) => {
        // console.log('feedback', user)
        return user;
      })
    )
  }
  // aws or upload
  upLoadResume(params) {
    return this.http.post(`${environment.apiLoginUrl}/route/upload/aws`, params).pipe(
      map((user: any) => {
        // console.log('feedback', user)
        return user;
      })
    )
  }
  getMetaData(params) {
    return this.http.post(`${environment.apiUrl}/route/WEB_META_TAG/pagetitle`, params).pipe(
      map((user: any) => {
        // console.log('feedback', user)
        return user;
      })
    )
  }

  getArticles = () => {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/articles`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getmiceDestination = () => {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/miceDestination`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getPartner() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/channelPartner`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getTestimonials() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/mice_testimonials/`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getMiceWebBanner() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/web-banner`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  getMiceGallery() {
    return this.http.get<any>(`${environment.apiLoginUrl}/route/miceForm/mice/gallery`)
      .pipe(
        map((user: any) => {
          return user;
        })
      )
  }
  setCanonicalURL(url?: string) {
    // const canURL = url == undefined ? this.dom.URL : url;
    // const link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', canURL);
    // console.log( canURL)
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
    // console.log( link)
  }
}