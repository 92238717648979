<section id="Home" class="pt-0 ">

  <!-- <div class="MuiBox-root css-1croez2" style="min-width: 100%;margin: auto;height: 100vh;"><video style="height:100vh;object-fit:cover" width="100%" loop="" playsinline="" autoplay="" muted="" preload="auto">
    <source src="../../assets/img/video-test.mp4" type="video/mp4"></video>
  </div> -->

  <!-- desktop video -->
  <div class="d-md-block d-none v-desk">
    <div class="v-desk__video">
      <!-- 16:9 Aspect Ratio -->
      <iframe title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowfullscreen
        src="https://www.youtube.com/embed/v83DdIV6yBs?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=v83DdIV6yBs"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;">
      </iframe>
      <div class="testC"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255, 0); z-index: 1;">
      </div>
    </div>
  </div>

  <!-- mobile video --->
  <div class="video-container d-md-none" >
   <div class="overlay"></div>
    <iframe title="YouTube video player" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      allowfullscreen
      src="https://www.youtube.com/embed/7u-hN3Y9NCM?start=0&autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&playlist=7u-hN3Y9NCM"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;">
    </iframe>
  </div>

  <!-- <video muted loop autoplay>
    <source src="../../assets/img/mm4.mp4" type="video/mp4">
  </video>  -->


  <!-- <owl-carousel-o class="hm-slider " [options]="customOptions" *ngIf="!isMobile">
    <ng-template carouselSlide *ngFor="let item of property">
      <div class="slider" style="background-image: url(&quot;{{item.mobImage}}&quot;); "
        [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
        <div class="content-card">
          <h2 class="content-card__h1"></h2>
          <span class="content-card__span">
          </span><br>
        </div>
        <div class="background-overlay"></div>
      </div>
    </ng-template>
  </owl-carousel-o> -->

  <!-- <owl-carousel-o class="hm-slider" [options]="customOptions" *ngIf="isMobile">
    <ng-template carouselSlide *ngFor="let item of property" >
      <div class="slider" style="background-image: url(&quot;{{item.image}}&quot;); " [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}" >
        <div class="content-card">
          <h2 class="content-card__h1" ></h2>
          <span class="content-card__span">
          </span><br>
         </div>
        <div class="background-overlay"></div>
      </div>
      
    </ng-template>
  </owl-carousel-o> -->
</section>