import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-accordion-view",
  templateUrl: "./accordion-view.component.html",
  styleUrls: ["./accordion-view.component.scss"],
})
export class AccordionViewComponent implements OnInit {
  public isCollapsed = false;
  hide = true;
  constructor() {}

  ngOnInit(): void {}
}
