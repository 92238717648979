import { Component, ElementRef, HostListener, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  isSticky: boolean = false;

  // for sticky on scroll
  /* https://stackblitz.com/edit/angular-material-sticky-header?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html,src%2Fapp%2Fapp.component.scss,src%2Fapp%2Fapp.component.spec.ts */

  @HostListener("window:scroll", ["$event"])
  checkScroll() {
   this.isSticky = window.pageYOffset >= 100;
  }
  constructor(private el: ElementRef, private renderer: Renderer2, private router:Router) {}

  ngOnInit(): void {
    // $(window).scroll(function () {
    //   var menu = $(window).scrollTop();
    //   menu > 70
    //     ? $(".topnav").addClass("sticky upper-navbar")
    //     : $(".topnav").removeClass("sticky upper-navbar");
    // });
  }

  // scrollToContact() {
  //   debugger
    
  //   const contactElement = this.el.nativeElement.querySelector('.contact');
  //   const yOffset = 200;
  //   const y = contactElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //   window.scrollTo({ top: y, behavior: 'smooth' });
  //   // this.router.navigate(['/Contact']);
  // }

  close(){    
    var mb = this.el.nativeElement.querySelector('.show');
    if (mb.classList.contains('show')) {
      mb.classList.add('hide');
      mb.classList.remove('show');
    }
  }
}
