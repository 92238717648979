import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newaccordion',
  templateUrl: './newaccordion.component.html',
  styleUrls: ['./newaccordion.component.scss']
})
export class NewaccordionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
