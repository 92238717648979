<!-- ======= Accordion Why Us Section ======= -->
<section id="why-us" class="why-us">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-5 align-items-stretch position-relative video-box pd-0" data-aos="fade-right">
        <div class="why-us__image-box clearfix">
          <div class="why-us__up">
            <img title="" src="../../assets/img/kesari-mice-director.jpg" alt="Kesari mice Director Zelam Chaubal" width="380" height="439" 
            loading="lazy">
          </div>
          <div class="why-us__back">
            <img title="" src="../../assets/img/team.jpg" width="292" height="325" alt="team" loading="lazy">
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-7 d-flex pd-left flex-column justify-content-center align-items-stretch"
        data-aos="fade-left">

        <h1 class="main-heading-all">
          Kesari MICE <br>
          <strong>Corporate Tour Experts</strong>
        </h1>
        <p>
          Led by <span class="para-name-highlight">Director Zelam Chaubal</span>, Kesari MICE caters to corporate
          clients of all sizes keen on leveraging the rapidly growing MICE
          (meetings, incentives, conferences and exhibitions) industry. Kesari
          MICE designs and delivers products and services that offer them an
          edge in an increasingly competitive world.
        </p>


        <div class="accor">

          <mat-accordion>
            <mat-expansion-panel class="panel-sepration">
              <mat-expansion-panel-header class="main-accordion-hedgs">
                From pre-event preparations to post-event
                settlements,</mat-expansion-panel-header>
              <mat-accordion class="example-headers-align">
                <p>
                  Kesari MICE provides a comprehensive package to ensure
                  personalized end-to-end service to corporate clients. It
                  specializes in arranging conferences, business meetings,
                  events, seminars, exhibitions,and incentives.
                </p>
              </mat-accordion>
            </mat-expansion-panel>
            <mat-expansion-panel class="panel-sepration">
              <mat-expansion-panel-header class="main-accordion-hedgs"> All a corporate client needs to do is outline
                basic requirements,</mat-expansion-panel-header>
              <mat-accordion class="example-headers-align">
                <p>
                  Fix a destination and the Kesari MICE team takes over the
                  rest. The committed team of Our Corporate Tour Experts handles
                  every detail, from travel to accommodation to entertainment
                  and allied events. A dependable tour manager from Kesari MICE,
                  trained to meet all challenges, accompanies the group and is
                  always available to provide any sort of assistance.
                </p>
              </mat-accordion>
            </mat-expansion-panel>

            <mat-expansion-panel class="panel-sepration">
              <mat-expansion-panel-header class="main-accordion-hedgs"> As Corporate Tour Experts “Kesari
                MICE”</mat-expansion-panel-header>
              <mat-accordion class="example-headers-align">
                <p>
                  Team has unmatched industry experience and the expertise
                  required to help companies optimize their MICE programs. Based
                  on extensive research and planning, the services offered by
                  the Kesari MICE team ensure an unparalleled and completely
                  tension-free experience.
                </p>
              </mat-accordion>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="example-action-buttons">
  <button mat-button>Expand All</button>
  <button mat-button>Collapse All</button>
</div> -->