import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { kesariAnimations } from "src/app/animation";
import { ShareService } from "src/app/service/share.service";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-enquiry",
  templateUrl: "./enquiry.component.html",
  styleUrls: ["./enquiry.component.scss"],
  animations: kesariAnimations,
})
export class EnquiryComponent implements OnInit {
  @ViewChild("dialogContainer") dialogContainer: ElementRef;
  contactForm: FormGroup;
  errorMessage: string;
  successMesage: string;
  formview: boolean = true;
  thankyouview: boolean;
  disableSubmitbtn: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private miceService: ShareService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<EnquiryComponent>
  ) {}

  ngOnInit(): void {
    this.getForm();
    this.setDialogRoleAttribute();
  }

  setDialogRoleAttribute() {
    if (this.dialogContainer && this.dialogContainer.nativeElement) {
      this.dialogContainer.nativeElement.setAttribute("role", "dialog");
    }
  }

  getForm() {
    this.contactForm = this.formBuilder.group({
      Name: [
        "",
        [Validators.required /*Validators.pattern(/^[a-zA-Z ' " .-]+$/)]*/],
      ] /* (/^[A-Za-z -]+$/) */ /* , Validators.pattern(/^[a-zA-Z .-]+$/) */,
      companyname: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
        ],
      ],

      contact: [
        "",
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/),
        ],
      ],
      grptype: [""],
      NoOfDays: ["", [Validators.pattern(/^[1-9]{1,4}$/)]],
      destination: [""],
      dateOfTravel: ["", [this.dateValidator]],
      description: ["", [Validators.maxLength(200)]],
    });
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.contactForm.controls[controlName].hasError(errorName);
  };
  dateValidator(control: FormControl): { [key: string]: any } | null {
    const inputDate = new Date(control.value);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    if (inputDate <= currentDate) {
      return { invalidDate: true };
    }

    return null;
  }
  onSubmit() {

    if (this.contactForm.valid) {
      console.log(JSON.stringify(this.contactForm.value, null, 4));
      this.miceService.getEnquiryForm(this.contactForm.value).subscribe(
        (response) => {
          console.log("response ", response);
          if (response.message == "Successfully Saved Your Lead") {
            this.contactForm.reset();

            this.formview = false;
            this.thankyouview = true;
            this.toastrService.success(
              "Your Response has been submitted succesfully !",
              "Thank you",
              {
                positionClass: "toast-top-center",
              }
            );
            this.closeDialog();
          } else {
            var message = "Form Submission Failed";
            if (response && response["response"]) {
              this.toastrService.error(
                "Form Submission Failed"
              );
            }
            //this.errorMessage = response; //Faild Message
          }
        },
        (error) => {
          // this.loading = false;
          // var message = "Sorry Failed to Submit Form. Please Try Again.";
          this.toastrService.error(
            "Form Submission Failed"
          );
        }
      );
    } else {
     // this.errorMessage = "Form has some Issues"; //Error Mesage
      // this.loading = false;
      this.toastrService.error(
        "Form Submission Failed"
      );
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
