<div class="subbanner" [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
    <!-- <img src="../../assets/img/itinary.jpg" class="Itineraries-country-banner" alt="banner-image" loading="lazy"> -->
    <div class="container position-relative">
       <div class="subbanner__title">
          <h1 class="primary-title">Terms and Conditions </h1>
       </div>
    </div>
 </div>

<div class="container padding-section">

<div class="terms" [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
  
    <p class="terms__subhed"><strong>Dear Guest(s),</strong></p>
    <p><strong class="terms__subhed">Tour Code : ___________________________________________________</strong></p>
    <p>We make every effort to provide you with the best services in a pleasant, friendly and homely atmosphere at all
        times. We strive for your complete satisfaction to provide a safe and secure journey to the best of our ability
        without any misunderstandings between your good-selves, Kesari and the tour Leaders. We believe in forgiveness,
        gratitude, positive attitude and innovation and at times even go beyond legal formats to ensure that all the
        issues of guest/s are resolved in timely and friendly manner. The Terms and Conditions - hereinafter referred to
        as Terms and Conditions given here-under and on our website, the tour registration form, any other printed
        material and Brochures, together with ‘How to book your tour’ section from the World Showcase form the basis of
        our agreement and understanding with you. Kesari &amp; Guests require to follow policies, rules &amp;
        regulations, laws &amp; orders of visiting destination and Countries. You are requested to read, understand and
        sign in acceptance all the Terms and Conditions and the Brochure, before you register yourself / family /
        friends for our tour as a 'Guest' to use our services.</p>
    <div class="terms__list">
        <h2>Short Title, Extent and Commencement:</h2>
        <ol type="i">
            <li>These Terms &amp; Conditions shall be called Kesari - (India &amp; World guests) Terms &amp; Conditions
            </li>
            <li>Terms and Conditions extend to whole of India and World Tours organized by the Kesari Tours Private
                Limited - hereinafter referred to as Kesari.</li>
            <li>Terms &amp; Conditions shall come in force from 1st December, 2019 &amp; It will remain in force till
                further modification or amendment</li>
        </ol>
        <ol type="I">
            <li><strong>Definitions:</strong> In these Terms and Conditions unless the context otherwise requires
                
                <ol type="i" class="mt-10">
                    <li><strong>Brochure</strong> means World Showcase for the respective years brought out / published
                        by Kesari.</li>
                    <li><strong>Company</strong> means Kesari Tours Private Limited and also includes all its divisions
                        and brands namely, Chota Break, My Fair Lady, Honeymoon Special, Second Innings, Marigold,
                        Prince Charming, Students Special including all and any other brands / divisions of Kesari</li>
                    <li><strong>First day of the tour</strong> shall start at any time - i.e. in the morning / afternoon
                        / evening at the first destination depending on the arrival of the respective flight / train /
                        cruise or any other mode of transport. The same shall apply in case of the last day of the tour.
                        In other words, a 'Day' shall mean a part of the day or 24 hours or its part thereof.</li>
                    <li><strong>Force Majeure</strong> means an event or circumstance not within the reasonable control,
                        directly or indirectly, of Kesari in its ability to perform its obligations / responsibilities
                        regarding the tour including (a) war, hostilities invasion, act of foreign enemies; (b)
                        rebellion, revolution, insurrection or military or usurped power or civil war; (c) epidemic,
                        explosion, fire, flood, earthquake or other exceptional natural calamity and act of God; (d)
                        ionizing radiation or contamination by radioactivity from any nuclear fuel, or from any nuclear
                        waste from the combustion of nuclear fuel, radioactive toxic explosive, or other hazardous
                        properties of any explosive nuclear assembly or nuclear component of such assembly; (e) riot,
                        commotion or disorder; (f) any act or omission of any Government instrumentality; (g) a Change
                        in Legal Requirements; (h) strikes, lock outs or other industrial disturbances; and (i) abnormal
                        weather or sea conditions including tempest, lightning, hurricane, typhoon, rain and
                        temperatures. (j) unforeseen cancellation by contractors like hotel, transport, suspension of
                        airlines, railways, Eurostar, bus services etc.</li>
                    <li><strong>Group Tours</strong> mean such tours which are carried out in groups containing various
                        individuals families and where the services provided are similar to all the guest/s in that
                        group.</li>
                    <li><strong>Guest(s)</strong> means the person in whose Name or on whose behalf booking and/or
                        registration is made for any of India and/or World Tours organized by Kesari.</li>
                    <li><strong>Independent Contractor</strong> means a person and / or organization selected by Kesari
                        to render services including transport, hotel, air, insurance, restaurant, sightseeing to the
                        guest/s and / or to Kesari. Independent Contractor is not an employee, partner or joint venture
                        of Kesari.</li>
                    <li><strong>Jain food</strong> means food without onion, garlic served at the time of tour groups.
                        Meals not necessarily root vegetables and provided before sunset and may or may not be served on
                        the table.</li>
                    <li><strong>Meal</strong> means breakfast, lunch, dinner, packed foods and / or any other snacks
                        (dry/wet) supplied.</li>
                    <li><strong>Tour/s</strong> means any Indian tours - also referred as 'domestic tours' carried out
                        within the state of India and World Tours - also referred as 'International Tours' carried out
                        in any foreign - Country organized by Kesari.</li>
                    <li><strong>Tour Leader / Assistant'</strong> by whatever designation called means a Person
                        designated by Company to help / guide / assist the guest/s in &amp; or during India and / or
                        World Tours Organized by Kesari</li>
                    <li><strong>Panaromic View:</strong> "Panoramic view" means an unobstructed and wide view of an
                        extensive area in all directions, an extended pictorial representation or a cyclorama of a
                        landscape or other scene, often exhibited a part at a time and made to pass continuously before
                        the spectators.</li>
                    <li><strong>Orientation Tour:</strong> A sightseeing tour is accompanied by a local city guide which
                        points out places of interest. Company may make stops depending upon permission from local
                        authority which may change from season to season.</li>
                    <li><strong>Working days:</strong> These are the days between and including Monday through Friday,
                        and do not include public holidays and weekends.</li>
                </ol>
            </li>
            <li><strong>General Explanations:</strong>
                <ol class="mt-10" type="i">
                    <li>Each provision/term of these Terms and Conditions shall be severable and if any provision
                        thereof is declared invalid, illegal or unenforceable, then remaining provisions nevertheless
                        shall have full force and effect.</li>
                    <li>The masculine gender, shall also include feminine gender and vice versa, the singular shall
                        include plural and vice versa and shall include grammatical variations thereof.</li>
                </ol>
            </li>
        </ol>
        <h2>Scope of Activity:</h2>
        <p>Kesari is an organization that coordinates all the services of the tour to help the guest/s for undertaking
            the tour. Kesari takes utmost care in selecting all the services and/or Independent Contractors who provide
            their services, to make our guest/s comfortable on tour. Kesari select various aspects of the tour such as
            hotel, restaurant, airline, coach, shipping company etc. and does not have any control over operating of the
            same. Though Kesari takes utmost care of our guest/s; Kesari shall not be held responsible for any loss,
            harm, injury, death, damage caused by default, omission or act of employees or management of any such
            agencies or Independent Contractors. All the guest/s booking and / or registering for tour organized /
            proposed to be organized by Kesari shall read, understand and abide by and strictly comply and observe all
            the terms and conditions laid down herein or in any / all other publications by Kesari. We endeavour to
            update our guest/s from time to time by sending the guest/s various tour related instructions through sms /
            emails / telephone/ or by any other mode of communication, solely for convenience of the guest/s. Guest/s
            should check mails regularly for updates sent from Kesari email id – tourupdates@kesari.in as well as check
            sms sent by Kesari on their mobile registered with Kesari. However, guest/s are advised to visit website
            www.kesari.in to keep yourself updated with the Terms and Conditions, as Kesari reserves right to change the
            Terms and Conditions at any time.</p>
        <h2>Brochure/World Show Case/Website/ Mobile App:</h2>
        <p>All previous Brochures / World Showcases, letters and any other modes of circulation including Tour tariff
            printed and / or published by Kesari shall stand cancelled on publication of Latest Brochure / World Show
            Case / website / Mobile App. Every effort is made to ensure accuracy in publication, based on the available
            information at the time of publication of the brochure. The maps used therein and/or letters and any other
            modes of circulation printed and/or published by Kesari are inserted solely for convenience of guest/s and
            are not necessarily to scale and / or official.</p>
        <p>If the time table, tour conditions and the situations then requires Kesari to change any brochure
            information, without assigning any reasons, Kesari reserves the right to change any brochure information
            including tour itinerary, tour tariff, departure date, number of nights at the destination and hotels before
            or after any guest makes booking and / or registration for any tour and / or after the departure of any
            tour. Kesari Please read Terms and Conditions. Terms and Conditions are also available on website
            www.kesari.in/termsandconditions. Page 1 of 8 shall endeavour to inform the guest/s of such changes as soon
            as possible. City tour includes panoramic view of places as mentioned in tour program and unless
            specifically mentioned, it is panoramic view or a photo stop. Last day of the tour shall be the last
            destination / country as per pre-decided / revised itinerary.</p>
        <h2>Booking:</h2>
        <ol type="i">
            <li><strong>Guest(s) signature on the tour Booking / Registration form:</strong>
                <p>Guest/s signature on the tour Booking / Registration form and/or payment of the initial registration
                    amount whichever earlier shall mean acceptance by the guest/s in totality of the Terms and
                    Conditions contained herein. Affixing signature of one or more guest/s if the group includes large
                    number of guest/s on the 'Booking / Registration form', shall be deemed to mean that the guest/s
                    other than signatories have duly authorized the signing guest/s and all the guest/s for whom the
                    Booking / Registration was made shall be deemed to have been bound by the terms and conditions
                    contained herein. Guest/s are requested to sign such forms, documents or other tour related papers
                    wherever it is required to sign these papers by the concerned guest/s only and not by any other
                    person on guest/s' behalf.</p>
            </li>
            <li><strong>Online Booking:</strong>
                <p>Guest/s can register and book tour / services online by paying initial amount through our website
                    www.kesari.in and / or through the booking link sent by Kesari staff. Booking will be processed on
                    the date of payment depending on seats availability. Online booking is handled from our office
                    situated at 314, L. J. Road, Mahim(West), Mumbai. Any dispute or grievances incurred due to online
                    booking shall have exclusive jurisdiction of Mumbai Courts only. We have secured our website with
                    protection technologies to ensure that your information will be kept secure and confidential.
                    However Kesari will not be responsible with respect to online booking due to any reason which is not
                    controllable such as booking engine's malfunction including software or network errors. Your online
                    booking needs your confirmation of terms and conditions mentioned on online registration form.
                    Guest/s needs to go through with our terms and conditions and privacy policy, which are displayed on
                    our website and confirm and accept in the course of booking online. Further, Guest/s will also need
                    to send / mail us a copy of the said signed Terms and Conditions confirming that you have read,
                    understood and accepted the Terms and Conditions and other applicable documents including world
                    Showcase and/or Itinerary and / or Price etc. Guest/s needs to send a copy of the first two pages
                    and last two pages of your passport which show the date of your passport, place and date of issue
                    and expiry, address etc. Online payment for your tour shall consider that you have accepted Terms
                    &amp; Conditions of booking Tour. Service charges for online payment is payable by Guest.</p>
            </li>
            <li><strong>Guest(s)' intention to participate in the tour:</strong>
                <p>The payment of initial registration amount just indicates guest/s' intention of participation in the
                    tour but does not entitle him to any of the services including travel tickets, visas, hotel
                    accommodations etc. until the full tour tariff payment has been received by Kesari.</p>
            </li>
            
            <li>
            <strong>Travelers in need of special assistance:</strong>
            <p>Special services shall not be given to any specific person in a group tour. Kesari, without committing
                itself or being held responsible in any way whatsoever, shall make reasonable attempt to accommodate the
                special needs of disabled travelers / senior citizens if informed with required documentations at the
                time of booking subject to availability &amp; with cost attached to it. Most transportation services are
                not equipped with wheel chair ramps. A qualified companion at the entire cost of the disabled
                guest/senior citizen must accompany the guest who may need such services / assistance as the same are
                not provided by Kesari and / or Independent Contractor to the other guest/s in the Group.</p>

            </li>
        <li>
            <strong>Minimum booking of the tour:</strong>
            <p>The Tour shall proceed only if the number of guest/s who have booked the tour exceed 20 and the said
                booking of 20 guest/s must be received by Kesari at least 30 calendar days prior to the scheduled start
                of the tour. If the number of guest/s booked for any tour falls below 20,then tour may be merged with
                other tour and / or tour may operate without Company tour Leader on small vehicle or mini coach and on
                MAP plan (accommodation with breakfast and dinner) and / or with an additional surcharge. However Kesari
                may cancel the tour without assigning any reason and in such an event the guest/s can claim only the
                amount paid by him after deducting the taxes &amp; VISA fees if paid by Kesari to Visa Processing centre
                or Consulate. Till the total number of guest/s reaches 20, the booking of the guest/s shall be strictly
                provisional. In the event of Kesari deciding to cancel the tour, cancellation of tour shall be
                communicated at least 15 calendar days prior to the date of commencement of tour via any of the
                following modes (a) sms (b) telephone (c) email; as per the information given by the guest/s or by any
                person for and on behalf of guest/s at the time of booking of the tour.</p>
            </li>
        <li>
                <strong>Merger of
                Tours:</strong>
                 <p>Tours are merged especially when minimum guest criteria is not met by one of the tour or
            both the tour and / or tours are departing from different locations. Tours can be merged partially or fully.
            In rare cases, Speciality tours may be merged with Family Tours. Kesari reserves the right to merge the
            tours. Seat allotment in Coach in merged tour shall be followed strictly as per tour booking date.
            </p>
        </li>
            <li>
                
                <strong>Health:</strong>
                <p>Guest/s hereby acknowledges that all the guest/s who have booked the tour have gone through the
                    itinerary and is well aware about the same and are Medically fit to undertake the said tour. The
                    Company reserves the right to ask the guest/s to provide written certification of his medical
                    fitness before departure. The persons who are medically fit to undertake the said tours only shall
                    join the said tour. The persons who are and / or who have suffered from any ailments for which they
                    have taken medical treatment and/or have gone into check up and/or hospitalization shall join the
                    tour at their own risk. In case of any health emergency or health related issues while touring, the
                    guest/s shall solely be responsible for it and to make their own arrangements on the tour for
                    medical assistance &amp; other expenses. Guest/s shall not expect the Tour leader to pay full time
                    attention and / or to provide any medical treatment. It is possible that the Tour Leader may be busy
                    in his work for making arrangements for rest of the group. Kesari reserves the right to restrict /
                    modify / amend the tour of such guest/s without assigning any reasons. and. No refund is applicable
                    in such case.</p>
            </li>
            <li><strong>Guest with medical ailment:</strong>
                <p>The guest/s suffering from any disease at the time of booking / registration is required to provide
                    the MD doctor's fitness certificate to undertake the tour. For Kailash Manasarovar tour maximum age
                    limit is 60 years or as may be decided by the competent authority from time to time and for the said
                    tour the medical fitness certificate of the guest/s is must. In case of death of guest/s, all the
                    arrangements for transportation of dead body including procuring the death certificate, postmortem,
                    repatriation of dead body and all personal effects/property and insurance claims etc. shall be made
                    by the accompanying guest/s and/or relative of the deceased. Company shall make all endeavour to
                    extend any help but cannot guarantee the same. The entire expenses shall be borne by the
                    accompanying guest/s or their relatives. Kesari or any tour leader shall not be responsible for the
                    same. If any of the relatives want to visit the country where the guest/s has passed away all the
                    necessary arrangement shall be made by the said relatives only.</p>
            </li>
            <li><strong>Persons other than those booked:</strong>
                <p>Persons, friends or relatives of the guest/s, who have not booked the tour shall not be allowed in
                    hotel rooms, restaurants, coaches and sight seeing places or for any activity on the tour..</p>
            </li>
        </ol>
        <h2>Insurance:</h2>
        <p>The tour cost may not include insurance charges unless it is mandatory as per the prevailing rules of the
            respective countries. Guest/s are advised and recommended to acquire adequate Insurance Policy and Overseas
            Travel Insurance cover as per requirement of Government of the Country concern to meet expense including
            medical, legal hospitalization, accident etc. during the tour. Presently for most of the European countries,
            it is mandatory to obtain an Overseas Travel Insurance policy for the tour. In Europe, Egypt and Dubai
            Tours, Kesari facilitates the insurance for guest/s upto the age of 59 years and guest needs to pay
            differential insurance charges for age exceeding 59 years over and above the tour tariff. It is obligatory
            for the guest/s to make full disclosure to Insurance Company of guest/s’ medical condition that may affect
            his ability to take up and complete the full tour. Insurance Company and / or service provider reserves the
            right to call for any written certification of guest/s’ medical fitness before departure. Kesari is
            insurance facilitator and is not liable / responsible for any reason whatsoever, for settlement of insurance
            claims and it is a matter solely between the concerned insurance company and the guest/s. Insurance
            companies do not insure baggage. Guest/s is / are advised not to carry valuables or money on tour and keep
            any valuables in cargo baggage. In case of any unfortunate event while on tour like hospitalization and / or
            unfortunate demise, it shall be the sole responsibility of the guest/s and / or relative of the hospitalized
            person/deceased to contact and submit the necessary claim to insurance authorities for further processing of
            the claim.</p>
        <h2>Payments by Guest:</h2>
        <ol type="i">
            <li><strong>Payment:</strong>
                <p>The registration amount paid at the time of booking, is not refundable and interest free. Tour Cost
                    in foreign currency need to be paid before processing of VISA. Balance payments by the guest/s shall
                    be made at the time of booking or 45 days before departure whichever is earlier. The bookings shall
                    be processed by Kesari only after the registration amount is paid in full. In case of billing
                    errors, Company reserves the right to re-invoice. Guest has to pay INR.300/- (Rupees Three Hundred
                    Only) for each instrument in case cheque dishonored by the bank. Kesari reserves the right to take
                    necessary legal action in respect of dishonored cheques as may be advised.</p>
            </li>
            <li><strong>Travel Loans:</strong>
                <p>Company welcomes the loan facilities granted by the banks to the eligible guest/s for any of the tour
                    if it fulfills the payment conditions of the tour laid down by Kesari. Kesari is however not
                    responsible / liable for grant / rejection / refund / repayment etc. of such loan facilities.</p>
            </li>
            <li><strong>Tour Tariff:</strong>
                <p>The tariff of the tour quotation is calculated as per the rates prevailing at the time of quoting it.
                    Total tour tariff should be paid 45 days before tour departure or due date mention on invoice which
                    ever is earlier. Interest @ the rate of 18% p.a. on daily basis will be charge on delay payment.
                    Kesari reserves the right to change the tariff in the event of modification / alteration / change /
                    variation in the said rates before the date of departure. Any such increase in the tour tariff
                    accordingly, must be paid in full by the guest/s before tour departure. Any increase in tour tariff
                    during the tour must be paid in full by the guest/s on tour. Tours start at the first destination
                    mentioned in Itinerary unless tours departing by Air. All tour tariffs are exclusive of all taxes
                    applicable from time to time. Guest hereby unconditionally accept that the prices, set of services
                    and inclusions may vary on product to product or departure to departure level and the same shall not
                    be considered for any kind of comparison universally. Tour tariff mentioned in our Brochure/ World
                    Show Case / website / Mobile App are at the time of publication &amp; subject to change.</p>
            </li>
            <li><strong>Discounts / Benefits:</strong>
                <p>Early booking of tour makes the guest/s eligible for the benefit or discount as declared by Kesari
                    which are valid for limited number of seats, limited period, specific tour dates and varies from
                    tour to tour, region to region and country to country, guest to guest and age of the guest. The
                    agreed amount of discount is subject to timely payments by guest/s i.e. minimum 45 days prior to the
                    tour departure date. Accordingly, Kesari reserves the right to amend, alter, vary or withdraw any of
                    the discounts / benefits without prior intimation to the guest/s. To be eligible for any discounts /
                    benefits guest must pay the full registration amount at the time of booking itself. Discounts/
                    benefits are applicable for guest/s whose age is 2 years and above for World tours and 5 years and
                    above for Indian tours.</p>
            </li>
            <li><strong>Foreign Exchange:</strong>
                <p>With a view to provide single window facility to the guest/s, Company is an authorized Money Changer
                    of Reserve Bank of India to make arrangements for the foreign exchange requirements of the guest/s.
                    Documentations ( A2 form,Passport copy, PAN copy etc) &amp; payments to avail Foreign Exchange
                    should be as per RBI Regulations. Guests should pay equivalent INR at the Rate of Exchange
                    prevailing on the day, minimum 45 working days prior to the departure of Tour by Local clearing
                    cheque / Demand draft / Net transfer in favour of "Kesari Tours Private Limited- Forex". to release
                    the foreign exchange. Service charges shall be applicable for payment through Credit / debit cards
                    and direct deposits of Foreign Currency with the Company as per Company policy.</p>
            </li>
            <li><strong>Airlines:</strong>
                <p>Airlines are solely responsible for overbooking of seats by the Airline, failure to provide meal as
                    required by the guest/s' choice, meals offered by the airline/quality of meal ,loss of baggage by
                    the Airline, failure on the part of Airline to accommodate guest/s despite having confirmed tickets,
                    flight delay, change in date or time, no permission to board on flight by Airlines, suspension of
                    operation of Airline etc and no claim of whatsoever nature shall be made by the guest/s against
                    Kesari. Kesari do not provide meals in flight. Allotment of seats are in the hands of the airline in
                    group booking. Guest is required to bare additional cost due to delay in flight or cancellation of
                    flight or missed the flight as the case may be. Guests are free to book their own tickets &amp;
                    refund will be as per contract between Kesari to Airline. Once Air tickets are released as per
                    request of guest, Kesari will not be responsible for new booking &amp; guests joining the group. In
                    case of any increase in Airport Taxes / Airport Development fees payable to the respective Airport
                    shall be borne and payable by guest/s. All the booking / cancellation / change of the airline ticket
                    and the travel on such airline ticket shall be subject to the terms and conditions of respective
                    airlines. High season surcharge as per the rules of the Airline concerned has to be paid by the
                    guest/s. Any increase in airfare, airport taxes, visa charges, fuel surcharges after the
                    booking/registration shall have to be paid by the guest/s. Kesari shall be only service facilitator
                    in case of all regional air departure and flight connectivity. Any pre or post program of Guest in
                    personal nature, shall be the responsibility of Guest only</p>
            </li>
            <li><strong>Tour tariff other than Ex-Mumbai (World tours):</strong>
                <p>Tour tariff is based on departure from Mumbai, India, unless specifically mentioned. Guest/s boarding
                    flights at places other than Mumbai have to pay the entire airfare difference. Any other additional
                    expenses including airport transfers, hotel stay, meals will be responsibility of Guest/s</p>
            </li>
            <li><strong>Reimbursement to Kesari </strong>for payment due and payable by the guest but made on his behalf
                by Kesari: Over and above the tour tariff paid by the guest/s if Kesari pays any money for and on behalf
                of the guest/s as per the requirement under law, force majeure or otherwise, the same shall be
                reimbursed by the concerned guest/s to Kesari forthwith on demand by Kesari.<p></p>
            </li>
            <li><strong>Direct payment to bank account:</strong> Guest should inform Kesari for any payment made by them
                to Kesari's bank account through net or payment gateway, within 24 hours with valid proof of payment by
                payment copy / email. Payment receipt will issued only after intimation by the guest to Kesari.</li>
        </ol>
        <h2>Document:</h2>
        <ol type="i">
            <li><strong>Passport:</strong>
                <p>Passport is an official document required whenever guest/s intend to travel abroad and in addition
                    the guest/s should have visa of that particular country which the guest/s wants to visit.</p>
                <p>Before submission of the passport to Kesari for obtaining visa, guest/s has to ensure that passport
                    validity is minimum 180 / 365 days from the arrival / conclusion date of the tour in India and it
                    has sufficient blank pages, which should not be damaged/stapled/torn etc. for recording the visas of
                    the respective countries.</p>
                <p>It is responsibility of guest to carry same ID proof on tour which is provided at the time of booking
                    .</p>
                <p>Child below 18 years require NOC, Custody Certificate, adoption certificate etc as the case may be.
                </p>
                <p>Foreign national shall ensure the valid VISA when the tour commences from India &amp; concludes in
                    India. Such Foreign national shall solely be responsible for any contingency in respect of the same.
                </p>
                <p>Non Resident Indian (NRI) shall compulsorily hold Person of Indian origin (POI) or Overseas citizen
                    of India (OCI) card while traveling to India and / or gap of minimum 2 months between two visits to
                    avoid deportation entry problem through immigration authorities.</p>
            </li>
            <li><strong>Loss of Passport:</strong>
                <p>Guest/s traveling abroad shall take care of not losing passport. Loss of passport on tour involves
                    costs and consequences such as filing FIR, visiting Indian Embassy, getting new passport or landing
                    certificate, expenses on the stay, food, transportation etc. all of which have to be borne and paid
                    by the concerned guest/s only. Getting the landing certificate or the new passport is the sole
                    responsibility of guest/s. Tour Leader is not responsible or liable or under obligation, for the
                    loss of passport and consequences thereof. Entire consequential expenses shall be borne by the
                    guest/s. Any other guest accompanying the guest who has lost the passport wishes to terminate the
                    tour intermittently shall also be responsible to meet the related / consequential expenses etc. No
                    further refund shall be given for the guest leaving the tour abruptly.</p>
            </li>
            <li><strong>Visa - World tours:</strong>
                <p>The guest/s interested going abroad must have a valid Visa to visit / travel to that Country /
                    Countries. Visa is the short form of 'Visitors Intending To Stay Abroad' and means the valid
                    permission granted by Government of country without which the guest/s can not visit / travel to that
                    country. Company generally assists in documentation and submission of application for visa by
                    forwarding them to the respective embassies and consulates on the dates as pre-defined by the
                    concerned authority. Company also generally assists guest/s for U.K. Visa documentation and / or
                    taking the interview date for U.S.A. visa. The visa fees charged to the guest/s shall be at the
                    rates prevailing as on the date of submission of documents to the Embassy or Consulate. Despite
                    submitting all relevant documents or attending personal interview, granting of Visa is at the sole
                    discretion of the Embassy or Consulate. Guests are advised to refer to FAQ / Documentation page on
                    website of concern VISA Consulate. It is responsibility of guest/s to check filled VISA form for
                    correctness before submitting to VISA Consulate.</p>
                <p>Kesari shall not be responsible for any errors in the date of Visa granted or delay in granting visa
                    or scanned photographs by the concerned authority. Guest shall be responsible for the photographs
                    specifications provided by different Consulate. Generally consulate do not gives any reason for not
                    granting visa and / or with refusal stamp on passport. Kesari therefore shall not provide any reason
                    why a person is not granted a visa. Kesari also can not give any reason why a particular person was
                    granted a visa as the same is at the discretion of embassy or consulate of respective countries.
                    Delay in submission of visa documents or rejection of visa for any reason is sole discretion of
                    concerned embassy / Visa Facilitate Centre over which Kesari has no control &amp; Kesari is not
                    responsible for the same. All costs and charges in respect of the said application for the visa as
                    intimated by Company shall be borne by the guest/s only. Company reserves the right to levy an
                    additional charge over and above the tour tariff to be paid and borne by guest/s for delay in
                    procuring visa by the guest/s and producing before Kesari for any reason whatsoever. Some Embassies
                    and Consulates and/or Visa facilitating Centre requires Guest to appear in person in order to
                    provide bio metric data (fingerprints, digital photography, etc) and / or Interview. Therefore
                    Guests will require to visit VISA facilitating Centre / embassies / consulates PAN India anywhere at
                    their own cost.</p>
                <p>Though guest/s submit VISA kits well in advance but Kesari may not submit their VISA applications due
                    to:</p>
                <p>a. Non-availability of Air tickets<br>b. Non-availability of Hotel Voucher<br>c. Last minute changes
                    in the rules and regulations of Embassy and Consulate.<br>d. Limited VISA submissions accepted by
                    Consulate / Consulates VISA facilitating Centre<br>e. Submission at given deadline by Embassy /
                    Consulate i.e. 1 month prior to departure etc.The process required for facilitation of Visa, depends
                    on various factors irrespective of submission of Visa kit by the guest/s to Kesari, whereby Company
                    reserves the sole right to commence the facilitation of visa process. On arrival Visa procedure may
                    change at any time by Immigration Authorities. Guest is solely responsible for unlawful Immigration,
                    if any and Kesari is not responsible for any refund. Embassy or Consulate may ask for Original
                    documents / Personal visit / interview for bio-metric or clarification at any time which guest needs
                    to provide. All kinds of Visa's such as Paper VISA, sticker VISA, E-VISA etc. should be checked by
                    the guest/s for Name, validity, correct photographs, signature of the authorized person etc.
                    Mandatory Documents for<br>i. Passport with 180-365 days validity from arrival date in India after
                    the tour - Check Names and Blank pages for VISAs as per requirement.<br>ii. Duly signed &amp; filled
                    VISA forms by each guest as per Passport signature &amp; details. For Children, take Thumb
                    impression - If passport has thumb impression or signature alongwith Parent's Signature - Male -
                    Left Thumb &amp; Female Right Thumb or as directed by Consulate.<br>iii. Personal Covering letter
                    stating purpose of the travel - Personal / Tour / Employment etc. along with financial / sponsorship
                    details. (For Employed guest on Plain paper, Self Employed on Plain paper with Visiting Card,
                    Business or Professionals on their letter head) Sometimes due to load factor at the embassies and
                    consulates, the VISA processing takes longer time and in few cases VISA is not granted before tour
                    departure or it is delayed. Incomplete and non - submission of Visa documents, shall be
                    responsibility of Guest only.</p>
                <p><strong>Guest/s Responsibility:</strong> It shall be sole responsibility of the guest/s to hold
                    required valid travel documents and statutory clearances including court permissions, including
                    passports, visas, confirmed travel tickets, insurance including medical insurance certificates, any
                    other document used to confirm the services of tour including an arrangement with independent
                    contractor &amp; other statutory certificates, to enable him / her to undertake tour. All travel
                    documents are non-transferable and must be issued in the name of the passport / photo identity
                    holder, otherwise the booking may be cancelled. Passport must be valid from the start of the tour to
                    at least 180 days subsequent to the scheduled arrival date of the tour - subject to rules of the
                    specific visa embassy or consulate. The guest/s shall ensure timely collection of all such documents
                    enabling them to undertake tour and be present in time for departure. Guest/s should keep certified
                    true copies and photocopies of all documents including valid travel documents and statutory
                    clearances including court permissions, including passports, visas, confirmed travel tickets,
                    insurance including medical insurance certificates, any other document used to confirm the services
                    of tour including an arrangement with independent contractor &amp; other statutory certificates,
                    used by him / her while on tour. Guest’s are requested to arrive at the airport 3 to 4 hours prior
                    to departure as per Airline guidelines.</p>
                <p><strong>Photo identification:</strong> All guest/s travelling for Indian / domestic sectors are
                    required to carry the photo identification in the form of driving license, election ID card, Aadhar
                    Card and for students their school / college ID card alongwith birth certificate. At certain
                    sightseeing places / hotel, the identification proof is required for which company has no control
                    and is mandatory. All guests are required to carry photo identification as required by the rules
                    &amp; regulations of that concern destination / country. Such rules varies from time to time and
                    country to country and available on official website of the consulate of respective countries.<br>In
                    case of Nepal and Bhutan Tours following Photo identification documents are required:<br>For Adult:
                    Voting Card (Election Card) in ORIGINAL OR Valid Passport with 6 months validity from the date of
                    return in ORIGINAL subject to Government Laws.<br>For Child: Valid Passport with 6 months validity
                    from the date of return in ORIGINAL OR School Photo ID and school bonafide certificate affix photo
                    on it with school stamp and signature with Birth certificate in ORIGINAL and it is mandatory that an
                    adult to accompany a child for Nepal and Bhutan.<br>For Infant: Valid Passport with 6 months
                    validity from the date of return in ORIGINAL OR Birth Certificate in ORIGINAL with at least 1 Parent
                    (father or mother) carrying all valid Photo identification documents mentioned above.<br>For NRI /
                    Foreigners: NRI guest required pay on arrival VISA / Govt Fees charges in Nepal, valid Indian VISA /
                    OCI before travelling to Bhutan and passport &amp; VISA / OCI copy must reach to Kesari one month
                    prior to departure for Bhutan.<br>Note: Nepal and Bhutan requires Original Passport OR Original
                    Birth Certificate only in English Language.</p>
                <p><strong>Itinerary Changes:</strong><br>i. Force Majeure Situations: In case of force majeure
                    conditions, Kesari reserves the right to change / modify / vary / alter the tour itinerary and / or
                    tour tariff accordingly. Such changes etc. shall be intimated to guest/s, prior to the start of the
                    tour or on tour as the case may be. Additional charges, if any, due to such changes etc. over and
                    above the tour tariff have to be paid by the guest/s before or on tour itself as the case may be. No
                    complaints / compensations and / or claiming of excess refunds beyond the provided refund by Kesari
                    - if any - by the guest/s shall be entertained by Kesari for limitation and / or non- availability
                    of certain services beyond the control of Kesari including museums, restaurants, sightseeing,
                    shopping etc. though included in tour itinerary. b. Due to certain unavoidable circumstances, other
                    than force majeure conditions, if any day of tour is curtailed / any sight seeing missed / any other
                    services not being provided due to some reason from Company's side, then Kesari shall be liable to
                    refund the guest/s, only that portion of the tour amount arrived at for that missed day / missed
                    sight seeing / missed services. However, guest/s are requested not to demand any other payment over
                    and above (if any) as than shall be made by Kesari in this regard.<br>Deviations: If any guest/s
                    wishes to deviate from the tour itinerary, Kesari shall permit such deviations at its discretion and
                    if possible. For such deviation, the guest/s has to pay full cost thereof in addition to the tour
                    tariff before the tour departure.<br>iii. Guest/s joining and / or leaving the tour at places other
                    than indicated in the tour:<br>a. Guest/s joining and / or leaving the tour shall observe following
                    alerts and shall ensure about the signing &amp; collection of Tour Registration Form from booking
                    staff and / or booking office at the time of booking:<br>i. Joining / Leaving tour price excludes:
                    Airfare - Main ticket, Internal Air Tickets if any, Visas, Insurance, snack hamper and airport
                    transfers. It do not have any connection with Regular Tour Cost. Foreigners / NRI Guests have to pay
                    any applicable charges while entering destination (e.g. permit in Bhutan). Kesari do not have any
                    responsibility relating to any arrangement due to delay in flights of joining leaving guests<br>ii.
                    Kesari will confirm Tour dates one month prior to the departure date. Purchase of non-refundable air
                    tickets at joining/leaving destination/ internal tickets is not advisable.<br>iii. Insurance is
                    mandatory for Europe, Egypt, Dubai and Jordan Israel tours and advisable on all world tours.<br>iv.
                    Complementary items to be collected before the tour from any of our nearest branch or Preferred
                    Sales Agents.<br>v. Guests must provide Telephone numbers, Active mobile numbers while touring,
                    email id, emergency contact, flight details for communication purpose<br>vi. Due to Airline
                    restrictions, the snacks hamper may not be possible to send on tour with Tour Leader<br>vii. Tour
                    routing / date may change &amp; in such case no compensation shall be made for the
                    consequences.<br>b. Guest/s should possess the entire air travel tickets including internal sectors
                    along with valid visas for the respective countries and insurance cover if required by the concerned
                    countries, unless otherwise informed.<br>c. If guest/s are reaching before the scheduled arrival
                    time of the tour at the respective airport then they should be present in time at the respective
                    airport arrival hall of the terminal where the concerned tour group is landing.<br>d. If guest/s are
                    reaching after the scheduled arrival time of the concerned tour the guest/s should join the tour
                    group directly at the hotel/1st destination/sight seeing place, etc. as the case may be on their own
                    and at their own costs and risks.<br>e. Such guest/s are required to provide details to Kesari 20
                    days in advance before the tour departs viz. Inward and outward flight / journey details, Photocopy
                    of an airline ticket, Email, mobile, contact particulars of the guest/s etc.</p>
                <p><strong>Transport:</strong><br>i. Coach / Bus Seating: Seat numbers are allotted on the basis of
                    booking dates for regular and merged tours. Seat Nos. 1,2,3,4 (front 4 seats) in each bus/ coach are
                    reserved for Kesari including Tour Leader / Guide etc. and / or as priority seats which can be
                    booked by guest/s subject to availability, on payment of additional tariff. Company reserves the
                    priority seat allocation rights. For priority seats, company doesn't guarantee for the specious
                    seats but only gives preference to seat ahead in bus / coach. In small vehicle, booking priority
                    will not be maintained &amp; rights to allocate seats is with Tour Leader. There is a strict "no
                    smoking", "no alcohol" and "no eating" policy in the travel mode. Kesari advises the guest/s to
                    carry all the items with them when they leave the travel mode for any reason. All baggage and
                    personal effects at all times and in all circumstances is the responsibility of the guest/s. Kesari
                    shall not be in any manner responsible or liable in case of loss of any items of the guest/s. Any
                    damages caused by the guest/s to the bus/coach and/or any other travel mode during the tour shall be
                    reimbursed by the guest/s to the concerned independent contractor and Company shall not be liable
                    for the same. Company reserves the right for selling / blocking of vacant seats in the coach on
                    daily basis, therefore group members shall keep those empty seats available for company use. Guest
                    has to abide by Coach Policies.<br>ii. World Tours Coaches / Buses: Company generally arranges
                    air-conditioned / air-cooled, luxury coaches. Such coaches may be equipped with an emergency
                    washroom, however this facility is not to be used in lieu of the rest rooms. In case, guest wishes
                    to use this facility, he shall have to pay tips to the driver of the coach directly. The size and
                    seating capacity of the coaches / buses generally varies from 20 to 40 and depending on the number
                    of the guest/s. Few coaches have one door or two door access, which varies from destination to
                    destination.<br>iii. Indian Tours Coaches / Buses: Company generally arranges air-conditioned /
                    air-cooled, Non-AC coaches, mini coaches, jeeps or taxies of various seating capacities depending on
                    the type of the tour, itinerary and number of guest/s, etc.</p>
                <p><strong>Hotels:</strong><br>i. Company takes care to select hotels and book the same on behalf of
                    guest/s at convenient locations subject to availability. Hotel accommodation is generally provided
                    on Twin sharing basis in standard rooms and the third bed is often a roll-away mattress put in a
                    twin bedded room. A family of four(4) is given two(2) rooms at most of the destinations around the
                    world, In such cases, child guest/s required to pay Tour tariff of adult . Most of the rooms have a
                    private bath or shower. Air-conditioning, central heating and other facilities are provided by the
                    hotels depending upon various factors including weather / climatic conditions, local systems, tour
                    type, etc. In Europe, heater facility is chargeable &amp; guest need to pay if required. At hill
                    stations, Air condition is chargeable &amp; guest need to pay if required. In case of any
                    improvements with hotel services, guest/s are requested to inform reception. All baggage and
                    personal effects under all circumstances is the sole responsibility of guest/s. Any damages to the
                    hotel room, visiting place, coach during the stay / visit / tour shall be the sole responsibility of
                    guest/s. Hotel's management / administration shall be solely responsible for all the service related
                    issues of the hotel staff. Guest/s availing additional facilities over and above provided by Kesari
                    such as mini-bar, paid television channels as well as any telephone/internet facilities etc are not
                    included in tour cost and guest/s shall bear the expenses for the same. Kesari reserves the right to
                    change the Hotel at any time. Guest/s are required to abide by Hotel Policies.<br>ii. Single
                    Occupancy: Single guest provided with single occupancy / single bedded room have to pay single
                    occupancy charges for the full tour. Size of single room is comparatively smaller than twin sharing
                    room and may be on different floor than groups rooms.<br>iii. Check in and check out time: Every
                    guest has to abide by the check-in and check-out time of the respective hotels. For Indian tours,
                    generally the check-in time is 12 noon and check-out time is 10 a.m. For World tours, generally the
                    check-in time is 3 p.m. and check-out time is at 12 noon. However, check-in and check-out may also
                    differ depending upon destination, hotel policy . If guest wants any change therein, guest should
                    manage the same at his own cost with hotel authorities on payment of additional charges, if
                    any.<br>iv. Additional facilities: Additional facilities including laundry, telephone, mini bar,
                    alcohol, beverages, food, optional excursions, excess baggage fees, shopping, paid toilet etc if
                    availed by the guest/s, have to be paid by the guest/s only before leaving the hotel, ship / cruise
                    or restaurant etc. as the case may be.<br>v. Room Sharing: Two single guest can decide to share a
                    hotel room, and/or accommodate a third guest booked on the same tour on triple sharing basis. The
                    excess amount collected as a single booking will be refunded within 10 days from arrival of tour.
                    Any misunderstandings or incidence like missing items or difference of opinion between them shall
                    have to be settled between room partners themselves. Company is not responsible for any loss or
                    mishap. Shared rooms have no smoking policy. Kesari reserves the right to allocate rooms for
                    Speciality Tours.</p>
                <p><strong>Restaurants:</strong><br>Kesari or any of its independent contractors shall not be bound to
                    serve non veg food items during en-route journey and / or en-route lunch halts for International /
                    Domestic Sector. Kesari shall endeavour to select the best quality restaurants for en-route lunch
                    halts in terms of food, sanitation facilities, hygiene, service but shall not have direct control
                    over their maintenance. Guest/s shall take note that the Restaurant Manager allots the table for the
                    group that shall depend upon the group size and infrastructure of the restaurant.</p>
                <p><strong>Meals:</strong> There is mostly a pre set menu for meals depending on the tour program.
                    Packed meals are served at some places. Company mostly serves Vegetarian food along with few Non -
                    Veg items. Kesari do not differentiate amongst the guest/s by providing a special meal or special
                    diet. In some cases only Veg food will be provided. In case of baby meal, Company generally provides
                    packed milk, plain dal and rice, apart from that if any different diet is required, the said guest/s
                    shall make their arrangements for the meal at their own costs and consequences. Kesari do not
                    provide meal at Airport and / or in the Air flight. No Complaints / Claims for refund in respect of
                    the meals shall be entertained and Kesari reserves the right to change the menu and arrangements of
                    the meals without assigning any reasons thereof.</p>
                <p><strong>Tour Leaders on Tour:</strong> Tour Leaders/assistants are assisting to the guest/s
                    throughout the tour, they are not responsible for guest/s baggage or any kind of personal
                    belongings. On the entire tour, the guest/s are responsible to carry the baggage on their own. The
                    instructions and information of the tour leader to amend, alter, vary, reshuffle the tour itinerary
                    without assigning any reason to guest/s, are to be followed in the group tours and tour leaders is
                    not responsible for any instructions overruled by the guest/s and its consequences. All
                    communications between Tour Leaders &amp; Guest/s will be in English or Hindi or Marathi.</p>
                <p><strong>Baggage:</strong>Guest/s are responsible for their own baggage. The baggage at the airport,
                    during any air travel, in coaches, in any train journey, at immigration points etc, guest/s have to
                    verify the rules and be responsible for the same. The porter - age or the tips for services rendered
                    to carry guest/s baggage to the rooms is guest/s responsibility and have to be paid directly to the
                    service provider. The "porter" or any helper arranged by the guest/s at any train stations,
                    airports, at immigration points or any other place, is the sole responsibility of the guest/s and
                    guest/s shall have to pay tips directly to them.</p>
                <p><strong>Cancellation Charges:</strong> You have booked group tour where Kesari requires to book
                    services like hotel, air, transportation etc. in advance because all group need to travel at the
                    same time and stay together in one hotel. Our moto is to avoid all inconvenience to guest &amp;
                    enjoy their tour happily. And for blocking the reservations, we need to make the payment in advance.
                    In such situation if tourist does not availed the services then we have to bare the cancellation
                    charges. In order to cover our expected losses from the cancellation there’s a set scale of charges
                    which must be paid by the guest if you or anyone traveling with you cancels the tour or cancel any
                    services or arrangements that form part of your booking with us. The cancellation charges are a
                    percentage of total tour cost which varies with the number of days prior to departure date.</p>
                <h2>Cancellation Charges are as follows:</h2>
                <div class="table-responsive">
                    <table class="table table-bordered table--dark-head">
                        <thead>
                            <tr>
                                <th></th>
                                <th>World Tours</th>
                                <th>Indian Tours</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Number of days before departure from the date of receipt of your cancellation
                                    request</td>
                                <td>Amount of cancellation charges in % on total tour price must be paid by the Guest/s
                                </td>
                                <td>Amount of cancellation charges in % on total tour price must be paid by the Guest/s
                                </td>
                            </tr>
                            <tr>
                                <td>More than 90 days</td>
                                <td>Registration Amount or 20% of tour cost whichever is higher</td>
                                <td>Registration Amount or 20% of tour cost whichever is higher</td>
                            </tr>
                            <tr>
                                <td>90 - 61 days</td>
                                <td>30%</td>
                                <td>30%</td>
                            </tr>
                            <tr>
                                <td>60 - 46 days</td>
                                <td>50%</td>
                                <td>50%</td>
                            </tr>
                            <tr>
                                <td>45 - 31 days</td>
                                <td>75%</td>
                                <td>75%</td>
                            </tr>
                            <tr>
                                <td>30 - 16 days</td>
                                <td>90%</td>
                                <td>90%</td>
                            </tr>
                            <tr>
                                <td>15 - 01 days</td>
                                <td>100%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>On the day of departure</td>
                                <td>100%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>On Tour</td>
                                <td>100%</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td colspan="3" align="center">Cancellation on Additional Service/Deviation will be
                                    charge extra GST is applicable on cancellation charges</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p><strong>A. General Terms applicable for all type of cancellations:</strong><br>1. Applicable Refund
                    as per cancellation policy will be paid within 10 working days from the date of cancellation in INR
                    only.<br>2. No interest will be applicable on refund<br>3. Cancellation shall attract the
                    cancellation charges on total tour price<br>4. For any additional services / deviation / upgradation
                    or any similar changes (over &amp; above Group tours of Kesari) booked by the guest, the additional
                    cancellation in addition to above the cancellation table will be applicable.<br>6. Kesari do not
                    have any responsibility towards additional expenses relating to any arrangement made by Guest
                    themselves relating to tour.<br>7. Guest should communicate any correspondence towards cancellation
                    on email cancellation@kesari.in.</p>
                <p><strong>B. Tour cancelled by guest due to personal reason:</strong><br>1. If the guest decides to
                    cancel the tour for any reason, he shall make a written application to the Kesari and/or email from
                    registered email-id of guest on cancellation@kesari.in and deliver it to the concerned sales booking
                    person / Branch-In-charge at the sales outlet where guest/s booked.<br>2. The letter or email must
                    contain details of tour code / form no, date of departure, Name and no. of guest's and the original
                    receipts issued by Kesari.<br>3. Cancellation by email will be valid only if sent on abovementioned
                    email address only. Any other form of request for cancellation shall not be considered as request
                    for cancellation.<br>4. Booking after cancellation will be treated as fresh booking for all
                    purpose.<br>5. Cancellation can be due to any personal reason whatsoever, including but not limited
                    to, the reason of inability to participate due to any illness, death, court order, tour cancelled by
                    sharing partner, requiring to pay single occupancy and the like.<br>6. Air Ticket Cancellation
                    charges plus deposit forfeit charges as are applicable under the rules of the concerned Airline are
                    to be paid by guest/s immediately.<br><br></p>
                <p><strong>C. Tours cancelled by Kesari:</strong><br>1. Kesari may cancel/reschedule the tour without
                    assigning any reason and in such an event the guest/s can claim only the amount paid by him after
                    deducting the taxes &amp; VISA fees if paid by Kesari to Visa Processing centre or Consulate.<br>2.
                    When Kesari decides to cancel the tour, cancellation of tour shall be communicated at least 15
                    calendar days prior to the date of commencement of tour, unless force majuere situation, via any of
                    the following modes (a) sms (b) telephone (c) email; provided by the guest/s in Tour Registration
                    form.</p>
                <p><strong>D. Cancellation due to VISA rejection – Deemed Cancellation</strong><br>1. Tour will be
                    treated as Deemed cancelled, unless intimated by guest in writing within 24 hours for applying VISA
                    second time, if VISA is not granted or delayed by Consulate for any reason whatsoever.<br>2. The
                    cancellation charges shall be Applicable from the date of intimation of cancelled VISA from guest to
                    Kesari.<br>3. Guest may apply second time for VISA at his own risk and consequences . If VISA get
                    rejected then new date of intimation will be considered as cancellation of tour date.<br>4.
                    Sometimes the VISA processing takes longer time and in few cases VISA is not granted before tour
                    departure or it is delayed. In such cases, Kesari as a facilitator shall not be responsible for any
                    consequences and / or liable for any refund, the guest shall have to bear all the cancellation and
                    re-booking charges for the next tour.<br>5. If VISA of some guests of the booking form is cancelled
                    and other guests of same form cancels the tour then cancellation charges as per above table is
                    applicable for all bookings<br>6. Consulates depending on their rules &amp; regulations sent VISA
                    &amp; passport directly to guest's residence or to Kesari. In both the cases, guest requires to
                    check correctness on VISA ( Paper VISA, sticker VISA, E-VISA etc.) for personal &amp; other details
                    such as Name, validity, correct photographs, signature of the authorized person etc. Kesari will not
                    be responsible if immigration office do not allow the guest to leave or enter the country.<br>7. In
                    many cases, consulate requires Air Ticket / Hotel Vouchers to process the VISA. On cancellation,
                    actual Air Charges / VISA fees are payable in addition to above cancellation table.</p>
                <p><strong>E. Cancellation due to force Majeure Situations</strong><br>1. Under force Majuere Situation,
                    Kesari reserves the right to Cancel, partly cancel and/or reschedule the tour considering safety of
                    the Guests.<br>2. No refund fully/partly applicable on cancellation under any circumstances due to
                    Force Majeure<br>3. Kesari will request suppliers for assisting in postponement of tour &amp; guest
                    can join the next possible date of same tour product. Guests are required to pay additional Cost, if
                    applicable.<br>4. Guest can also opt for other tour product where cancellation will be applicable
                </p>
                <p><strong>F. Rescheduling of tour due to Uncontrollable factors</strong><br>1. Kesari reserves the
                    right to reschedule the tour and / or change the itinerary due to uncontrollable factors such as
                    change in airline schedules, suspension or discontinuation of services by suppliers, major event at
                    the visiting destinations affecting route of transport etc. No refund is applicable in
                    uncontrollable situations and guest have to abide by the new schedule / Itinerary.<br>2. Guest is
                    required to pay additional cost due to delay in flight or cancellation of flight or missed the
                    flight, missing the connecting flight during travel as the case may be. Guests are free to book
                    their own tickets &amp; refund will be processed as per contract between Kesari and Airline. Once
                    Air tickets are released as per request of guest, Kesari will not be responsible for new booking
                    &amp; guests will require to join group at their own cost &amp; consequences.<br>3. Fine, penalties
                    or any such payments arising due to not meeting with the requirements of any airport or government
                    authorities including Passport or Visa documents, court orders are responsibilities of guests and to
                    be paid by the guest.</p>
                <p><strong>G. Cancellation due to non payment of Tour price - Deemed cancellation</strong><br>1. If full
                    tour tariff is not paid as per terms &amp; conditions, Kesari reserves the right to cancel the
                    booking / registration with consequent forfeiture of deposit / advance amount/entire part payment
                    made to Kesari and / or the deduction of cancellation charges without giving notification, at the
                    discretion of Kesari.<br>2. The bookings shall be processed by Kesari only after the registration
                    amount is paid in full.<br>3. Single guest opting for sharing the room with other guest to reduce
                    cost from single occupancy to double occupancy will require to pay cost for single occupancy if for
                    any reason, related guest cancels or alters the booking of the tour. No adjustment shall be made
                    from the payment contributed by guest / partner who had cancelled/alter the tour. Shared rooms have
                    no smoking policy. Kesari reserves the right to allocate rooms for Speciality Tours such as My fair
                    Lady, Student Specials, My Prince Charming where booking is on single guest basis and guest has to
                    abide by it.</p>
                <p><strong>H. Right of Admission &amp; termination</strong><br>1. Guest with medical ailment: If any
                    guest/s is suffering from any medical ailment and have concealed the same, Kesari reserves the right
                    to cancel the booking / registration of such guest/s on medical and / or other grounds without
                    assigning reasons thereof. Kesari also reserves the right to restrict / modify / amend the tour of
                    such guest/s without assigning any reasons. If Kesari so directs, the guest/s shall have to travel
                    by a separate transport at an additional cost to be paid by guest/s immediately. No refund is
                    applicable to guest.<br>2. Kesari reserves the rights to cancel the booking / registration of any of
                    the guest after acceptance of the payment but prior to commencement of the tour, without assigning
                    any reason. In such event Kesari shall only refund the payment received from the guest for the
                    tour.<br>3. Kesari reserves the rights to terminate the booking / registration, prior to departure /
                    during tour, if the guest is not following the group decorum of the tour or misbehaves or without
                    assigning any reason whatsoever. In such an event, no refund or compensation shall be paid to the
                    guest and he shall have to bear and pay the expenses for his return travel.</p>
                <p><strong>I. No show – Deemed cancellation</strong><br>1. If guest fails to join the tour on Day 1 of
                    the tour, it will be termed as No show - deemed cancellation &amp; 100% cancellation will be
                    applicable<br>2. Kesari issues multiple air tickets for the tour under single PNR. As per airline
                    rules, on missing any of the flights in Single PNR become No Show and no refund is available.<br>3.
                    In above case , all the services will stand cancel unless guest notifies his willingness to join the
                    tour later at his own cost &amp; consequences within 24 hours from the date &amp; time of departure.
                </p>
                <p><strong>J. Discontinuation from ongoing tour</strong><br>1. If Guest discontinues / terminates the
                    tour for any personal reason, it is termed as deemed cancellation &amp; no refund will be
                    applicable<br>2. Kesari assists guests for booking of services in case of discontinuation of tour
                    due to medical reason , however guest or his relatives will require to pay cost of services in
                    advance on tour to tour leader or at Kesari offices.</p>
                <p><strong>K. Transfer of tour / Future tour:</strong><br>1. A transfer from the originally booked tour
                    to another tour is treated as cancellation of earlier tour and therefore a fresh booking of another
                    tour has to be made.<br>2. The tour tariff and discounts available / given for the cancelled tour
                    are not valid for fresh booking. Tour tariff and discounts prevailing on the date of fresh booking
                    of another tour shall be applicable.</p>
                <p><strong>16. Complementary Items:</strong><br>Company reserves the right, without assigning reasons,
                    to change the policy related to complementary items, without prior intimation to guest/s. No claims
                    shall be entertained in regards to non utilization of complimentary items.</p>
                <p><strong>17. Miscellaneous:</strong><br>i. Complaints or Grievances: If the guest has any claim,
                    grievance and / or complaint in respect of services provided by any of the independent contractors,
                    the guest shall immediately notify the same to the independent contractor in writing, and a copy
                    thereof is handed over to the tour / leader / / Assistant and /or Company not later than 7 days
                    thereof to enable Kesari to take up the matter with the concerned independent contractor.<br>ii.
                    Privacy of Information: Any Information furnished by the guest/s to the tour / leader/ / Assistant
                    and/or Kesari, is subject to disclosure thereof as required by law or by an order of court or the
                    rules, regulations and inquiry by any government / statutory authority. Company shall not disclose
                    any data of its own under any circumstances to any guest/s including any kind of clarification on
                    itinerary, tour costs, cancellation charges etc.<br><strong>iii. Government rules for NRI - Non
                        Resident Indian Guest:</strong> All guest/s staying abroad including NRIs or foreigners should
                    abide by all the laws, rules, regulations of Government of India and/ or the country concerned while
                    undertaking the tour.<br>iv. Photographs or Video Clips: Company reserves the right to use
                    Photographs / Video Clips of the guest taken on tour and / or given by guest, for promotion of
                    Kesari Business without obtaining prior permission of the concerned guest. Such Photographs / Video
                    Clips can be used on any media with due respect for emotions/sentiments of guest/s.<br>v. Reference
                    Call: Company reserves the right to refer the guest, on request, to prospective guest for promotion
                    of Kesari business. Further Company is authorized to send transaction as well as promotional
                    sms/e-mail/phone calls.<br>vi. Valuables: Kesari advises guest/s not to carry valuable items to the
                    extent possible with them on tour and invariably carry such items, if any, with them while leaving
                    the hotel for whatever reasons and not to keep any valuables, money or important travel documents
                    etc. in suitcase. Better keep them in safe deposit lockers. In case of any loss suffered by the
                    guest/s Kesari is not liable or responsible for the same.<br>vii. Shopping: Shopping is one of the
                    important activity of any tour and an experience in itself. All guest/s are hereby made aware that
                    Kesari neither recommends nor promote any specific shop or vendor for shopping purpose. We
                    understand that the guest/s are wise enough to decide themselves what to buy and where to buy. It is
                    a sole discretion of the Guest. Hence, Kesari do not take any responsibility or liability in case of
                    any cheating or misguiding by the shop owner.<br>viii.External guest/s not allowed: External guest/s
                    are not to be invited on tour by any guest and they are not allowed to use any of the services of
                    the tour.<br>ix. Paid Toilets: At some places / destinations / country, there are paid toilet halts,
                    which guest/s have to take care on their own.<br>x. Jurisdiction: All disputes pertaining to the
                    tour and any claim arising there under shall be subject to the Court of competent jurisdiction at
                    Mumbai only.</p>
                <p><strong>18. Refund:</strong><br>i. Refunds if any, for variation / modification / amendments /
                    alteration and / or cancellations etc. of any tour shall be paid directly to the guest by 'A/C
                    payee' cheque or Bank Transfer, in Indian Rupees at the prevailing rate of exchange on the date of
                    the cheque, as per Reserve Bank of India Rules &amp; Regulations, irrespective of whether the tour
                    payments in part or whole were made in foreign currency.<br><strong>ii. No refund shall be
                        payable:</strong><br>a. for any missed / unused services of the tour including the meals due to
                    whatsoever reason.<br>b. where full deposit is required to be paid in advance to the concerned
                    authorities to enable Company to confirm the reservation.<br>c. if services of the tour are
                    modified, varied, amended, cancelled or not utilized.<br>d. if any guest decides and/or is required
                    to cancel the tour due to any changes made in the rules by the concerned Government.<br>e. where
                    Airfares are contracted in bulk for Airport to Airport.<br>f. if the tour is indefinitely postponed
                    / cancelled due to natural calamity, riots, political calamities or any other unforeseen
                    calamities.<br>g. refund is not applicable for International / domestic sectors and on certain
                    conditions which include that such air tickets are non refundable and date change is not
                    allowed.<br>h. Kesari is not liable to pay any amount in case the VISA is not received by the guest
                    and / or Company within time. Kesari has to depend on various agents to do the processing work. In
                    case a person misses out the tour because of curtailed itinerary, he cannot claim any amount from
                    Kesari. Since, grant / non-grant of VISA and also communication of information in respect of grant /
                    non-grant of VISA is beyond the control of Kesari, the guest should be mentally prepared for any
                    mental / physical dismay as a result of non-grant of VISA and / or delayed communication which may
                    be just before departure of the tour or on the tour itself.</p>
                <p><strong>#19. Reservations:</strong><br>Kesari reserves the right to:<br>i. give admission to any
                    person for any tour or FIT package or for currency exchange;<br>ii. amend, alter, vary or withdraw
                    any tour including its services, flight route etc. advertised and/or published without assigning any
                    reason whatsoever;<br>iii. amend, alter, vary or withdraw the discounts/benefits or the priority
                    booking of bus seats given, if the minimum registration amount is not paid at the time of booking or
                    if any cheque drawn in favour of Kesari is dishonored, and / or without assigning any reasons
                    whatsoever;<br>iv. to vary, add, amplify or waive any stipulation, representation, term or condition
                    set forth in this Brochure.</p>
                <p><strong>#20. Liability and Responsibility of Kesari:</strong><br>i. The liability of Kesari is
                    restricted only to the making reservation in accordance with the guest/s requirements and no
                    further.<br>ii. Company is not liable for<br>a. any loss of person or property caused or damaged or
                    suffered by the guest/s on account of deficiency in services by any independent contractor. Any
                    gesture of courtesy extended by Kesari to the guest/s to minimize such loss or damage shall not
                    constitute an admission of such liability or a waiver. Kesari shall in no circumstances whatsoever
                    be liable to the guest/s or any other person for any loss or expenses incurred due to theft or any
                    other reason whatsoever during the tour and the entire responsibility of taking care of their
                    valuables shall be that of the guest/s.<br>b. train / flight delays, on account of any reason
                    whatsoever rescheduling / cancellation of train / flights inter-connection meals not being served
                    etc.<br>c. any overstay expenses on account of any delay or changes in air, bus, ship, train or
                    cancellation of special bogie on account of Force Majeure including weather conditions, strike, war,
                    quarantine, or any other reason whatsoever,<br>d. any Sightseeing missed and/or the program being
                    curtailed after commencement of the tour and / or before the due period on account of Force Majeure
                    including any unavoidable circumstances and any reason beyond company control including political,
                    natural calamity, monument under renovation, VIP movement and security, heavy rush at sightseeing
                    places, road traffic congestion etc.,<br>e. any tour, holiday, excursion facility exceeding the
                    total amount paid or agreed to be paid by the guest/s for the tour, including any consequential loss
                    or additional expense whatsoever if the guest/s is not allowed to board the train / flight on
                    account certain reasons beyond the control of Kesari.<br>f. any loss of baggage by independent
                    contractor or their agents, hotel staff, bus / coach or during tour including damage or loss caused
                    to the guest due to Force Majeure, negligence or technical fault or any other reason whatsoever on
                    account of any change, amendment or cancellation in their schedule by Airline / Railway
                    Authority.<br>g. allocation of seats, and food service in the aircraft.<br>h. any celebrity assigned
                    for the tour as an entertainer not turning up on the tour due to the reasons beyond the control of
                    Kesari.<br>i. any personal injury, sickness, accident, loss, delay, discomfort, consequential loss
                    and / or damage or any damage to baggage or personal effects howsoever caused.<br>j. death of the
                    guest while on tour and all applicable rules and procedures of law are to be followed strictly and
                    all the procedures pertaining to the death shall have to be tackled and handled by the guest or
                    their relatives themselves at their own cost &amp; consequences.<br>k. any injury, loss, personal
                    harm, death or damage caused to any person or property by the act or default of the management /
                    employees / agents of any independent contractor.<br>l. any damages, additional expenses or any
                    consequential losses suffered by the guest/s due to Cancellation, Amendment and / or alteration of
                    any of the services of the tour mentioned in the itinerary / Brochure by Kesari after the
                    registration / booking by the guest/s.<br>All or any information given in these Terms and Conditions
                    is based on the information available at the time of publication. Kesari reserves the right to
                    change any information before or after booking the tour due to circumstances / events beyond our
                    control. In case of knowledge of any change, in advance, we or any of our representative shall
                    accordingly notify the guest/s of any such changes by sms and / or email. Kesari Management would be
                    happy to meet the guest/s for any improvement points. Management considers all its guest/s as family
                    members and is sure and have confidence that guest/s shall approach the management for any
                    suggestions / improvements.</p>
                <p>I, _________________________________________________, have read the above rules and regulations
                    Serial No 1 to 20 and abide by it and accept the Terms and Conditions with unconditional consent,
                    without modification of the terms, conditions and notices contained herein</p>
                <p>Signature in acceptance:</p>
                <p>Name:</p>
                <p>Date:</p>
                <p>Place:</p>
            </li>
        </ol>
    </div>
</div>

    </div>