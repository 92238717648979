import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { throwError } from "rxjs";
import { ShareService } from "../service/share.service";

export interface PhotosApi {
  albumId?: number;
  id?: number;
  title?: string;
  url?: string;
  thumbnailUrl?: string;
}
@Component({
  selector: "app-slider-view",
  templateUrl: "./slider-view.component.html",
  styleUrls: ["./slider-view.component.scss"],
})
export class SliderViewComponent implements OnInit {
  partnerData: any;
  limit: number = 10;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    dots:false,
    autoWidth: true,
    //items:8, 
    responsive:{
      0:{
          items:3,
          nav:true
      },
      600:{
          items:5,
          nav:false
      },
      1000:{
          items:8,
          nav:true,
          loop:true
      }
  },
    navText: [
      "<div class='swiper-button-prev swiper-button-black itinerary-banner__b-btn'></div>",
      "<div class='swiper-button-next swiper-button-black itinerary-banner__b-btn'></div>",
    ],
  };
  constructor(private readonly http: HttpClient, private miceService: ShareService) {}

  ngOnInit(): void {
    this.fetch();
  }
  // tslint:disable-next-line:typedef
  fetch() {
    this.miceService.getPartner().subscribe((param) => {
      this.partnerData=param.data
    //  console.log('partnerData', this.partnerData)
    })   
  }
}
