import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-view-photos',
  templateUrl: './vertical-view-photos.component.html',
  styleUrls: ['./vertical-view-photos.component.scss']
})
export class VerticalViewPhotosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
