import { Component, OnInit } from '@angular/core';
import { CareerformComponent } from './careerform/careerform.component';
import { MatDialog } from '@angular/material/dialog';
import { kesariAnimations } from '../animation';

@Component({
  selector: 'app-career-section',
  templateUrl: './career-section.component.html',
  styleUrls: ['./career-section.component.scss'],
  animations: kesariAnimations,
})
export class CareerSectionComponent implements OnInit {
  public isCollapsed = true;


  constructor() { }

  ngOnInit(): void {
  }
 
}
