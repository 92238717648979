import { Component, OnInit } from '@angular/core';
import { kesariAnimations } from '../animation';

@Component({
  selector: 'app-horizontal-view',
  templateUrl: './horizontal-view.component.html',
  styleUrls: ['./horizontal-view.component.scss'],
  animations: kesariAnimations,
  
})
export class HorizontalViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
