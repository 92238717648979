<!-- ======= slider-view Section ======= -->


<section class="client-section sec-padding">
<div class="container">
    
        <h2 class="main-heading-all text-center">Trusted By over 1000+ Companies </h2>

  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let item of partnerData">
      <ng-template class="slide" carouselSlide>
        <img [src]="item.logo" class="" style="width: 116px;" width="116" height="36" [alt]="item.company_name" loading="lazy">
      </ng-template>
      <!-- <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/innova.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Innova Life Science" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/sakal.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Sakal Group of Publications" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/reliance.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Reliance Consumer finance" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/bajaj.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Bajaj Allianz Insurance co Ltd." loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/pidilite.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Pidilite Industries Ltd" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/hdfc-fund.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="HDFC Mutual Fund" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/vertex.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="RVertex Software" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/rotary.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Rotary Club" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/lions.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Lions Club" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/quick-here.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Quickheal Technologies" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/wockhardt.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Wockhardt Ltd." loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/novartis.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Novartis India" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/bajaj-auto.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Bajaj Auto Ltd." loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/coca-coca.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Coca cola India Pvt. Ltd." loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/CA.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="C.A. Association" loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/mit-group.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="MIT Group of Institutes." loading="lazy">
      </ng-template>
      <ng-template class="slide" carouselSlide>
        <img src="./assets/img/client-images/symboysis.jpg" class="" style="
       width: auto !important;  margin-right: 50px;" alt="Symboysis Group of Institutes" loading="lazy">
      </ng-template> -->
    </ng-container>

  </owl-carousel-o>
</div>
</section>
<!-- slider-view End Clients Section -->