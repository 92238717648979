<!-- ======= horizontal  Section ======= -->
<div id="icon-boxes" class="icon-boxes">
  <div class="container">
   <div class="row mob-vw">
      <div class="col-3  mob-vw__icon-box">
        <a routerLink="/Memorable">          
          <img src="../../assets/img/memorable.gif" width="42" height="42" loading="lazy" alt="Memorable MICE">
          <span>Memorable MICE</span> 
        </a>
      </div> 
      <div class="col-3  mob-vw__icon-box">
        <a routerLink="/Testimonial">          
          <img src="../../assets/img/books.gif" width="42" height="42" loading="lazy" alt="Customer Reviews">
          <span>Customer Reviews</span>
        </a>
      </div>
      <div class="col-3  mob-vw__icon-box">
        <a routerLink="/Gallery">          
          <img src="../../assets/img/gallery.gif" width="42" height="42" loading="lazy" alt="Photo Gallery">
          <span>Photo Gallery</span>
        </a>
      </div>
      <div class="col-3  mob-vw__icon-box">
        <a routerLink="/Article-Corner">                    
          <img src="../../assets/img/article.gif" width="42" height="42" loading="lazy" alt="News & Article">
          <span>News &
            Article</span>
        </a>
      </div>



    </div>


    <div class="row desk-vw" [@animate]="{value:'*',params:{delay:'5ms',x:'-25px'}}">
      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3 mb-lg-0" data-aos="fade-up">
        <div class="icon-box">
          <div class="icon-title">
            <div class="icon">
              <img src="../../assets/img/memorable.gif" alt="Memorable MICE" width="50" height="50" loading="eager" />
            </div>
            <h4 class="title">
              <a routerLink="/Memorable">Memorable MICE</a>
            </h4>
          </div>
          <p class="description">
            Meaningful, Motivational & Memorable MICE-What makes incentive
            travel experiences more meaningful motivational and
          </p>

          <a routerLink="/Memorable">
            <button type="button" class="btn btn-primary fw-bold btn-sm fw-bold read-morebtn">
              Explore
            </button></a>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
        <div class="icon-box">
          <div class="icon-title">
            <div class="icon">
              <img src="../../assets/img/books.gif" alt="Testimonials" width="50" height="50" loading="eager"/>
            </div>
            <h4 class="title"><a routerLink="/Testimonial">Testimonials</a></h4>
          </div>
          <p class="description">
            Innovative Thinking, Proactive Planning, Clear Communication and
            Efficient Systems ensure achieve client objectives.
          </p>
          <a routerLink="/Testimonial">
            <button type="button" class="btn btn-primary btn-sm fw-bold  read-morebtn">
              Explore
            </button>
          </a>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3 mb-lg-0" data-aos="fade-up" data-aos-delay="200">
        <div class="icon-box">
          <div class="icon-title">
            <div class="icon">
              <img src="../../assets/img/gallery.gif" alt="Photo Gallery" width="50" height="50"  loading="eager"/>
            </div>
            <h4 class="title"><a routerLink="/Gallery">Photo Gallery</a></h4>
          </div>
          <p class="description">
            KESARI MICE takes complete ownership of any event – every minute
            detail has our special touch, and nothing is ever left to chance.
            You have to experience it to believe it!
          </p>
          <a routerLink="/Gallery">
            <button type="button" class="btn btn-primary btn-sm fw-bold  read-morebtn">
              Explore
            </button></a>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
        <div class="icon-box">
          <div class="icon-title">
            <div class="icon">
              <img src="../../assets/img/article.gif" alt="News and Articles" width="50" height="50" loading="eager"/>
            </div>
            <h4 class="title"><a routerLink="/Article-Corner">News and Articles</a></h4>
          </div>
          <p class="description">
            Travelling through time , Zelam Chaubal director Kesari MICE
            reminisces about her journey in the travel industry so far...."
          </p>
          <a routerLink="/Article-Corner">
            <button type="button" class="btn btn-primary btn-sm fw-bold  read-morebtn">
              Explore
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Icon Boxes Section -->