<!-- ======= tab-view Portfoio Section ======= -->
<section id="Itineraries" class="portfoio section-pdng pb-0">
  <div class="container pds-0" data-aos="fade-up">

    <h2 class="main-heading-all text-center">MICE Itineraries</h2>

    <div class="portfolio-section position-relative">
      <owl-carousel-o [options]="customOptions">
        <ng-container>
          <ng-template carouselSlide *ngFor="let slide of itinenary">
            <img [src]="slide.image" class="portfolio-section__itinaries" width="314" height="250" [alt]="slide.country" loading="lazy"
              (click)="navigateToItineraries(slide.country)">
            <div class="portfolio-section__caption">
              <h4 class="primary-title">{{slide.country}}</h4>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

  </div>
</section>
<!-- End tab-view Portfoio Section -->

<!-- <div class="row portfolio-container">
  <div class="col-lg-3 col-md-6 portfolio-item filter-app">
    <img
      src="../../assets/img/mice-itinaries/India_2018.jpg" width="100%" height="267px"
      class="img-fluid"
      alt=""
    />
    <div class="portfolio-info">
      <h4>India</h4>
    
      
    </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-web">
    <img
      src="../../assets/img/mice-itinaries/Dubai_2018.jpg" width="100%" height="267"
      class="img-fluid"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Dubai</h4>
      </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-app">
    <img
      src="../../assets/img/mice-itinaries/2018_MalaysiA.jpg" width="100%" height="267"
      class="img-fluid"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Malaysia</h4>
      
      
    </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-card">
    <img
      src="../../assets/img/mice-itinaries/Singapore_2018.jpg" width="100%" height="267"
      class="img-fluid"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Singapore</h4>
     </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-web">
    <img
      src="../../assets/img/mice-itinaries/Hon-Kong (1).jpg" width="100%" height="267"
      class="img-fluid mice-country"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Hong-Kong</h4>
    </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-app">
    <img
      src="../../assets/img/mice-itinaries/Thailand (1).jpg" width="100%" height="267;"
      class="img-fluid mice-country"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Thailand</h4>
     </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-card">
    <img
      src="../../assets/img/mice-itinaries/Dambulla.jpg" width="100%" height="267"
      class="img-fluid mice-country"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Sri-Lanka</h4>
     </div>
  </div>

  <div class="col-lg-3 col-md-6 portfolio-item filter-card">
    <img
      src="../../assets/img/mice-itinaries/switzerlandss.jpg" width="100%" height="267"
      class="img-fluid mice-country"
      alt=""
    />
    <div class="portfolio-info">
      <h4>Switzerland</h4>
     
      
    </div>
  </div>

 
</div> -->